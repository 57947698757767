import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import SupervisorDashboard from './components/supervisor/SupervisorDashboard';
import BrigadeList from './components/brigada/BrigadeList';
import RoleList from './components/Role/RoleList';
import UserList from './components/User/UserList';
import SectorList from './components/sectores/SectorList';
import CircunscripcionList from './components/Circunscripcion/CircunscripcionList';
import Mapa from './components/mapa/mapa';
import Imagen from './components/imagenes/imagen';
import Login from './Login';
import Cookies from 'js-cookie';
import ErrorLayout from './shared/pages/ErrorLayout';
import apiService, { eventEmitter } from './shared/services/ApiService';
import './App.css';

// Import icons (you can use any icon library like react-icons/fa)
import { 
  FaBars, FaChartBar, FaUserTie, FaUsers, FaUsersCog, 
  FaLayerGroup, FaMapMarkedAlt, FaMap, FaSignOutAlt 
} from 'react-icons/fa';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('token'));
    const [userRole, setUserRole] = useState(Cookies.get('role') || null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleApiError = (status) => {
            window.location.href = `/error/${status}`;
        };

        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsMobile(true);
                if (isSidebarOpen) setIsSidebarOpen(false);
            } else {
                setIsMobile(false);
            }
        };

        // Initialize sidebar state based on screen size
        handleResize();

        eventEmitter.on('apiError', handleApiError);
        window.addEventListener('resize', handleResize);
        
        if (userRole) {
            setIsAuthenticated(true);
        }

        return () => {
            eventEmitter.off('apiError', handleApiError);
            window.removeEventListener('resize', handleResize);
        };
    }, [userRole, isSidebarOpen]);

    const handleLogin = (role) => {
        setIsAuthenticated(true);
        setUserRole(role);
    };

    const handleLogout = () => {
        Cookies.remove('role');
        Cookies.remove('userId');
        apiService.post('/api/auth/logout');
        setIsAuthenticated(false);
        setUserRole(null);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebarOnMobile = () => {
        if (isMobile) {
            setIsSidebarOpen(false);
        }
    };

    return (
        <Router>
            <div className="app-container">
                {isAuthenticated && (
                    <>
                        <aside className={`sidebar ${isSidebarOpen ? '' : 'collapsed'}`}>
                            <button onClick={toggleSidebar} className="menu-button">
                                <FaBars />
                            </button>
                            <h3 className="titulo-sidebar">Gestión de Brigadas</h3>
                            <nav>
                                <ul className="lista-sidebar">
                                    <li data-title="Estadisticas">
                                        <Link to="/dashboard" onClick={closeSidebarOnMobile}>
                                            <span className="icon"><FaChartBar /></span>
                                            <span className="text">Estadisticas</span>
                                        </Link>
                                    </li>
                                    {(userRole === 'Administrador' || userRole === 'Supervisor') && (
                                        <li data-title="Supervisor">
                                            <Link to="/supervisor" onClick={closeSidebarOnMobile}>
                                                <span className="icon"><FaUserTie /></span>
                                                <span className="text">Supervisor</span>
                                            </Link>
                                        </li>
                                    )}
                                    {userRole === 'Administrador' && (
                                        <>
                                            <li data-title="Brigadas">
                                                <Link to="/brigadas" onClick={closeSidebarOnMobile}>
                                                    <span className="icon"><FaUsers /></span>
                                                    <span className="text">Brigadas</span>
                                                </Link>
                                            </li>
                                            <li data-title="Usuarios">
                                                <Link to="/usuarios" onClick={closeSidebarOnMobile}>
                                                    <span className="icon"><FaUsersCog /></span>
                                                    <span className="text">Usuarios</span>
                                                </Link>
                                            </li>
                                            <li data-title="Roles">
                                                <Link to="/roles" onClick={closeSidebarOnMobile}>
                                                    <span className="icon"><FaUsersCog /></span>
                                                    <span className="text">Roles</span>
                                                </Link>
                                            </li>
                                            <li data-title="Sectores">
                                                <Link to="/sectores" onClick={closeSidebarOnMobile}>
                                                    <span className="icon"><FaLayerGroup /></span>
                                                    <span className="text">Sectores</span>
                                                </Link>
                                            </li>
                                            <li data-title="Circunscripcion">
                                                <Link to="/circunscripcion" onClick={closeSidebarOnMobile}>
                                                    <span className="icon"><FaMapMarkedAlt /></span>
                                                    <span className="text">Circunscripcion</span>
                                                </Link>
                                            </li>
                                            <li data-title="Mapa">
                                                <Link to="/mapa" onClick={closeSidebarOnMobile}>
                                                    <span className="icon"><FaMap /></span>
                                                    <span className="text">Mapa</span>
                                                </Link>
                                            </li>
                                        </>
                                    )}
                                    <li data-title="Cerrar Sesión">
                                        <button onClick={handleLogout}>
                                            <span className="icon"><FaSignOutAlt /></span>
                                            <span className="text">Cerrar Sesión</span>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </aside>
                        {isMobile && isSidebarOpen && (
                            <div className="mobile-menu-overlay" onClick={toggleSidebar}></div>
                        )}
                    </>
                )}
                <main className={`main-content ${isSidebarOpen ? '' : 'collapsed'}`}>
                    <Routes>
                        <Route
                            path="/login"
                            element={
                                isAuthenticated ? (
                                    <Navigate to="/" />
                                ) : (
                                    <Login onLogin={handleLogin} />
                                )
                            }
                        />
                        {isAuthenticated ? (
                            <>
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/supervisor" element={userRole === 'Administrador' || userRole === 'Supervisor' ? <SupervisorDashboard /> : <Navigate to="/dashboard" />} />
                                <Route path="/brigadas" element={userRole === 'Administrador' ? <BrigadeList /> : <Navigate to="/dashboard" />} />
                                <Route path="/usuarios" element={userRole === 'Administrador' ? <UserList /> : <Navigate to="/dashboard" />} />
                                <Route path="/roles" element={userRole === 'Administrador' ? <RoleList /> : <Navigate to="/dashboard" />} />
                                <Route path="/sectores" element={userRole === 'Administrador' ? <SectorList /> : <Navigate to="/dashboard" />} />
                                <Route path="/circunscripcion" element={userRole === 'Administrador' ? <CircunscripcionList /> : <Navigate to="/dashboard" />} />
                                <Route path="/mapa" element={userRole === 'Administrador' ? <Mapa /> : <Navigate to="/dashboard" />} />
                                <Route path="/" element={userRole === 'Administrador' ? <Imagen /> : <Navigate to="/dashboard" />} />
                            </>
                        ) : (
                            <Route path="*" element={<Navigate to="/login" />} />
                        )}
                        <Route path="/error/:code" element={<ErrorLayout />} />
                    </Routes>
                </main>
            </div>
        </Router>
    );
};

export default App;