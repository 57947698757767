import React, { useState, useEffect } from 'react';
import '../../css/sectorForm.css';

const SectorForm = ({ onSubmit, sector, brigades, circunscripciones, isOpen, onClose,onGuardar,onCancelar }) => {
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [status, setStatus] = useState('Pendiente');
    const [assignedBrigade, setAssignedBrigade] = useState('');
    const [circunscripcion, setCircunscripcion] = useState('');
    const [scheduledDate, setScheduledDate] = useState('');
    const [coordinates, setCoordinates] = useState(sector?.coordinates || []);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        if (sector) {
            setName(sector.name);
            setUrl(sector.url);
            setStatus(sector.status);
            setAssignedBrigade(sector.assignedBrigade || '');
            setCircunscripcion(sector.circunscripcion || '');
            setScheduledDate(sector.scheduledDate ? new Date(sector.scheduledDate).toISOString().substring(0, 10) : '');
            setCoordinates(sector.coordinates || []);
        }
    }, [sector]);

    const handleAddCoordinate = () => {
        setCoordinates([...coordinates, [0, 0]]);
        setCurrentPage(coordinates.length);
    };

    const handleCoordinateChange = (index, lat, lng) => {
        const newCoordinates = [...coordinates];
        newCoordinates[index] = [lat, lng];
        setCoordinates(newCoordinates);
    };

    const handlePrevious = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const handleNext = () => {
        if (currentPage < coordinates.length - 1) setCurrentPage(currentPage + 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, url, status, assignedBrigade, circunscripcion, scheduledDate, coordinates });
        setName('');
        setUrl('');
        setStatus('Pendiente');
        setAssignedBrigade('');
        setCircunscripcion('');
        setScheduledDate('');
        setCoordinates([]);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h3>{sector ? 'Editar Sector' : 'Nuevo Sector'}</h3>
                    <button className="modal-close" onClick={onClose}>&times;</button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="sector-name">Nombre del Sector</label>
                            <input
                                id="sector-name"
                                type="text"
                                placeholder="Nombre del Sector"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="sector-url">URL</label>
                            <input
                                id="sector-url"
                                type="text"
                                placeholder="URL"
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="sector-circunscripcion">Circunscripción</label>
                            <select
                                id="sector-circunscripcion"
                                value={circunscripcion}
                                onChange={(e) => setCircunscripcion(e.target.value)}
                                required
                            >
                                <option value="">Selecciona una Circunscripción</option>
                                {circunscripciones.map((circ) => (
                                    <option key={circ._id} value={circ._id}>
                                        {circ.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="sector-date">Fecha Programada</label>
                            <input
                                id="sector-date"
                                type="date"
                                value={scheduledDate}
                                onChange={(e) => setScheduledDate(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="sector-status">Estado</label>
                            <select 
                                id="sector-status"
                                value={status} 
                                onChange={(e) => setStatus(e.target.value)}
                            >
                                <option value="Pendiente">Pendiente</option>
                                <option value="Proceso">Proceso</option>
                                <option value="Listo">Listo</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="sector-brigade">Brigada Asignada</label>
                            <select 
                                id="sector-brigade"
                                value={assignedBrigade} 
                                onChange={(e) => setAssignedBrigade(e.target.value)}
                            >
                                <option value="">Selecciona una Brigada</option>
                                {brigades.map((brigade) => (
                                    <option key={brigade._id} value={brigade._id}>{brigade.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="coordinates-section">
                        <div className="coordinates-header">
                            <h4>Coordenadas</h4>
                            <button 
                                type="button" 
                                className="btn-add-coordinate"
                                onClick={handleAddCoordinate}
                            >
                                Agregar Coordenada
                            </button>
                        </div>
                        
                        {coordinates.length > 0 ? (
                            <div className="coordinate-card">
                                <div className="coordinate-info">
                                    <p>Coordenada {currentPage + 1} de {coordinates.length}</p>
                                    <div className="coordinate-inputs">
                                        <div className="form-group">
                                            <label htmlFor="latitude">Latitud</label>
                                            <input
                                                id="latitude"
                                                type="number"
                                                step="0.000001"
                                                placeholder="Latitud"
                                                value={coordinates[currentPage][0]}
                                                onChange={(e) => handleCoordinateChange(currentPage, parseFloat(e.target.value), coordinates[currentPage][1])}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="longitude">Longitud</label>
                                            <input
                                                id="longitude"
                                                type="number"
                                                step="0.000001"
                                                placeholder="Longitud"
                                                value={coordinates[currentPage][1]}
                                                onChange={(e) => handleCoordinateChange(currentPage, coordinates[currentPage][0], parseFloat(e.target.value))}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pagination-controls">
                                    <button 
                                        type="button" 
                                        className="btn-paginate"
                                        onClick={handlePrevious} 
                                        disabled={currentPage === 0}
                                    >
                                        &larr; Anterior
                                    </button>
                                    <button 
                                        type="button" 
                                        className="btn-paginate"
                                        onClick={handleNext} 
                                        disabled={currentPage === coordinates.length - 1}
                                    >
                                        Siguiente &rarr;
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="no-coordinates">
                                <p>No hay coordenadas. Haga clic en "Agregar Coordenada" para comenzar.</p>
                            </div>
                        )}
                    </div>

                    <div className="form-actions">
                        <button type="submit" className="btn-submit">
                            {sector ? 'Actualizar' : 'Guardar'} Sector
                        </button>
                        <button type="button" className="btn-cancel" onClick={onClose}>
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SectorForm;