import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import Notification from '../Notification';
import '../../css/dashboard.css';
import apiService from '../../shared/services/ApiService';
import Cookies from 'js-cookie';

Chart.register(...registerables);

const Dashboard = () => {
    const [sectors, setSectors] = useState([]);
    const [circunscripcions, setCircunscripcions] = useState([]);
    const [brigadas, setBrigadas] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [activeView, setActiveView] = useState('bar'); // 'bar' o 'pie'
    const [filters, setFilters] = useState({
        circunscripcion: '',
        sector: '',
        brigada: '',
        estado: ''
    });
    const userRole = Cookies.get('role');
    const userId = Cookies.get('userId');
    const [summary, setSummary] = useState({
        pendiente: 0,
        proceso: 0,
        listo: 0,
        total: 0
    });
    const today = new Date().toISOString().split('T')[0];

    // Colores consistentes para estados
    const statusColors = {
        'Pendiente': '#ef5ba1', // Rosa
        'Proceso': '#ffea00',   // Amarillo
        'Listo': '#00a651'      // Verde
    };

    useEffect(() => {
        const filteredNotifications = sectors.filter(sector => {
            const sectorDate = sector.scheduledDate?.split('T')[0];
            return sectorDate === today;
        }).map(sector => ({
            message: `Sector ${sector.name} programado para hoy`,
            status: sector.status
        }));
        setNotifications(filteredNotifications);
    }, [sectors, today]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [sectorRes, circunsRes, brigadaRes] = await Promise.all([
                    apiService.get(`/api/sectores`),
                    apiService.get(`/api/circunscripciones`),
                    apiService.get(`/api/brigadas`)
                ]);

                let sectorsData = sectorRes.data || [];

                if (userRole !== "Administrador") {
                    // Filtrar sectores según el usuario (supervisor solo ve lo asignado)
                    sectorsData = sectorsData.filter(sector =>
                        sector.assignedBrigade && sector.assignedBrigade.members.includes(userId)
                    );
                }

                setSectors(sectorsData);
                setFilteredData(sectorsData);
                setCircunscripcions(circunsRes.data || []);
                setBrigadas(brigadaRes.data || []);

                // Calcular el resumen solo con los sectores visibles
                updateSummary(sectorsData);
            } catch (error) {
                console.error("Error al cargar datos:", error);
                setNotifications([...notifications, {
                    message: "Error al cargar datos. Intente de nuevo.",
                    status: "error"
                }]);
            }
        };

        fetchData();
    }, [userRole, userId]);  // Dependencias para actualizar cuando cambie el usuario o rol

    const updateSummary = (data) => {
        const summary = data.reduce((acc, sector) => {
            acc.total++;
            if (sector.status === 'Pendiente') acc.pendiente++;
            else if (sector.status === 'Proceso') acc.proceso++;
            else if (sector.status === 'Listo') acc.listo++;
            return acc;
        }, { pendiente: 0, proceso: 0, listo: 0, total: 0 });

        setSummary(summary);
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        const newFilters = { ...filters, [name]: value };
        setFilters(newFilters);

        const filtered = sectors.filter(sector => {
            return (
                (newFilters.circunscripcion ? sector.circunscripcion === newFilters.circunscripcion : true) &&
                (newFilters.sector ? sector.name === newFilters.sector : true) &&
                (newFilters.brigada ? sector.assignedBrigade === newFilters.brigada : true) &&
                (newFilters.estado ? sector.status === newFilters.estado : true)
            );
        });

        const result = filtered.length ? filtered : sectors;
        setFilteredData(result);
        updateSummary(result);
    };

    const clearFilters = () => {
        setFilters({
            circunscripcion: '',
            sector: '',
            brigada: '',
            estado: ''
        });
        setFilteredData(sectors);
        updateSummary(sectors);
    };

    const prepareBarData = () => {
        // Agrupar por estado para barras más significativas
        const statusGroups = filteredData.reduce((acc, sector) => {
            if (!acc[sector.status]) {
                acc[sector.status] = [];
            }
            acc[sector.status].push(sector);
            return acc;
        }, {});

        const labels = Object.keys(statusGroups);
        const data = labels.map(status => statusGroups[status].length);
        const backgroundColors = labels.map(status => statusColors[status]);

        return {
            labels,
            datasets: [
                {
                    label: 'Cantidad de Sectores',
                    data,
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors,
                    borderWidth: 1,
                    borderRadius: 8,
                    barThickness: 50,
                },
            ],
        };
    };

    const preparePieData = () => {
        return {
            labels: ['Pendiente', 'En Proceso', 'Listo'],
            datasets: [
                {
                    data: [summary.pendiente, summary.proceso, summary.listo],
                    backgroundColor: [statusColors['Pendiente'], statusColors['Proceso'], statusColors['Listo']],
                    borderColor: ['#fff', '#fff', '#fff'],
                    borderWidth: 2,
                },
            ],
        };
    };

    const barOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',  // Barras horizontales para mejor visualización
        plugins: {
            title: {
                display: true,
                text: 'Sectores por Estado',
                font: {
                    size: 18,
                    weight: 'bold'
                }
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        return `Estado: ${tooltipItems[0].label}`;
                    },
                    label: (context) => {
                        return `Cantidad: ${context.raw} sectores`;
                    }
                }
            },
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                grid: {
                    display: false
                }
            },
            x: {
                grid: {
                    display: true,
                    color: 'rgba(0, 0, 0, 0.05)'
                },
                ticks: {
                    precision: 0
                }
            }
        }
    };

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: 'Distribución de Sectores',
                font: {
                    size: 18,
                    weight: 'bold'
                }
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.label;
                        const value = context.raw;
                        const percentage = ((value / summary.total) * 100).toFixed(1);
                        return `${label}: ${value} (${percentage}%)`;
                    }
                }
            },
            legend: {
                position: 'bottom',
                labels: {
                    padding: 20,
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            }
        }
    };


    return (
        <div className="dashboard-container p-6 bg-gray-50 rounded-lg shadow-sm">
            <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-gray-800">Panel de Control de Sectores</h2>

                <div className="flex space-x-2">
                    <button
                        className={`px-4 py-2 rounded-md ${activeView === 'bar' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                        onClick={() => setActiveView('bar')}
                    >
                        Barras
                    </button>
                    <button
                        className={`px-4 py-2 rounded-md ${activeView === 'pie' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
                        onClick={() => setActiveView('pie')}
                    >
                        Pastel
                    </button>
                </div>
            </div>

            {/* Resumen de estadísticas */}
            <div className="grid grid-cols-4 gap-4 mb-6">
                <div className="bg-white p-4 rounded-lg shadow-sm border-l-4 border-blue-500">
                    <h3 className="text-lg font-semibold text-gray-600">Total</h3>
                    <p className="text-3xl font-bold text-gray-800">{summary.total}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm border-l-4" style={{ borderColor: statusColors['Pendiente'] }}>
                    <h3 className="text-lg font-semibold text-gray-600">Pendientes</h3>
                    <p className="text-3xl font-bold text-gray-800">{summary.pendiente}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm border-l-4" style={{ borderColor: statusColors['Proceso'] }}>
                    <h3 className="text-lg font-semibold text-gray-600">En Proceso</h3>
                    <p className="text-3xl font-bold text-gray-800">{summary.proceso}</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-sm border-l-4" style={{ borderColor: statusColors['Listo'] }}>
                    <h3 className="text-lg font-semibold text-gray-600">Completados</h3>
                    <p className="text-3xl font-bold text-gray-800">{summary.listo}</p>
                </div>
            </div>

            <div className="grid grid-cols-12 gap-6">
                {/* Filtros en la barra lateral */}
                <div className="col-span-3 bg-white p-4 rounded-lg shadow-sm">
                    <h3 className="text-lg font-semibold mb-4 text-gray-700">Filtros</h3>

                    <div className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Circunscripción</label>
                            <select
                                name="circunscripcion"
                                value={filters.circunscripcion}
                                onChange={handleFilterChange}
                                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="">Todas</option>
                                {circunscripcions.map(circuns => (
                                    <option key={circuns._id} value={circuns._id}>{circuns.name}</option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Sector</label>
                            <select
                                name="sector"
                                value={filters.sector}
                                onChange={handleFilterChange}
                                className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            >
                                <option value="">Todos</option>
                                {sectors.map(sector => (
                                    <option key={sector._id} value={sector.name}>{sector.name}</option>
                                ))}
                            </select>
                        </div>

                        {/* Brigada (Solo visible para administradores) */}
                        {userRole === "Administrador" && (
                            <div>
                                <label className="block text-gray-700">Brigada:</label>
                                <select
                                    name="brigada"
                                    value={filters.brigada}
                                    onChange={handleFilterChange}
                                    className="w-full p-2 border rounded"
                                >
                                    <option value="">Todas</option>
                                    {brigadas.map(brigada => (
                                        <option key={brigada._id} value={brigada._id}>{brigada.name}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Estado</label>
                            <div className="flex space-x-2">
                                {Object.keys(statusColors).map(estado => (
                                    <button
                                        key={estado}
                                        onClick={() => handleFilterChange({ target: { name: 'estado', value: filters.estado === estado ? '' : estado } })}
                                        className={`flex-1 py-2 px-3 rounded-md text-sm ${filters.estado === estado ? 'text-white' : 'text-gray-700 bg-gray-100'}`}
                                        style={{ backgroundColor: filters.estado === estado ? statusColors[estado] : '' }}
                                    >
                                        {estado}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <button
                            onClick={clearFilters}
                            className="w-full mt-4 bg-gray-200 text-gray-700 py-2 rounded-md hover:bg-gray-300"
                        >
                            Limpiar Filtros
                        </button>
                    </div>
                </div>

                {/* Gráficos principales */}
                <div className="col-span-9 bg-white p-4 rounded-lg shadow-sm">
                    <div className="h-96">
                        {activeView === 'bar' ? (
                            <Bar data={prepareBarData()} options={barOptions} />
                        ) : (
                            <Pie data={preparePieData()} options={pieOptions} />
                        )}
                    </div>

                    {/* Tabla de datos filtrados */}
                    {filteredData.length > 0 && (
                        <div className="mt-8">
                            <h3 className="text-lg font-semibold mb-3 text-gray-700">Detalle de Sectores ({filteredData.length})</h3>
                            <div className="overflow-x-auto">
                                <table className="min-w-full bg-white border border-gray-200">
                                    <thead>
                                        <tr className="bg-gray-100">
                                            <th className="py-2 px-4 border-b text-left">Sector</th>
                                            <th className="py-2 px-4 border-b text-left">Circunscripción</th>
                                            <th className="py-2 px-4 border-b text-left">Brigada</th>
                                            <th className="py-2 px-4 border-b text-left">Estado</th>
                                            <th className="py-2 px-4 border-b text-left">Fecha Programada</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.slice(0, 5).map((sector) => {
                                            const brigada = brigadas.find(b => b._id === sector.assignedBrigade);
                                            const circun = circunscripcions.find(c => c._id === sector.circunscripcion);

                                            return (
                                                <tr key={sector._id} className="hover:bg-gray-50">
                                                    <td className="py-2 px-4 border-b">{sector.name}</td>
                                                    <td className="py-2 px-4 border-b">{circun ? circun.name : '-'}</td>
                                                    <td className="py-2 px-4 border-b">{brigada ? brigada.name : '-'}</td>
                                                    <td className="py-2 px-4 border-b">
                                                        <span className="px-2 py-1 rounded-full text-xs text-white"
                                                            style={{ backgroundColor: statusColors[sector.status] }}>
                                                            {sector.status}
                                                        </span>
                                                    </td>
                                                    <td className="py-2 px-4 border-b">
                                                        {sector.scheduledDate ? new Date(sector.scheduledDate).toLocaleDateString() : '-'}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                {filteredData.length > 5 && (
                                    <div className="text-center mt-2">
                                        <span className="text-sm text-gray-500">Mostrando 5 de {filteredData.length} sectores</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Notificaciones */}
            <div className="fixed bottom-6 right-6 space-y-2 max-w-sm">
                {notifications.map((notification, index) => (
                    <Notification
                        key={index}
                        message={notification.message}
                        status={notification.status}
                        onClose={() => setNotifications(notifications.filter((_, i) => i !== index))}
                    />
                ))}
            </div>
        </div>
    );
};

export default Dashboard;