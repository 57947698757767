import "./ErrorStyles.css";
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
const ErrorLayout = () => {
    const { code } = useParams();

    const errorMessages = {
      400: 'Solicitud incorrecta. Verifica los datos enviados.',
      401: 'No estás autorizado para ver esta página.',
      403: 'Acceso prohibido.',
      404: 'Página no encontrada.',
      500: 'Error interno del servidor.',
    };
  return (
    <div className="error-container">
      <div className="error-card">
        <div className="error-icon">
          <span className="error-code">{code}</span>
        </div>
        <p className="error-message">{errorMessages[code] || 'Ocurrió un error desconocido.'}</p>
        <Link to="/" className="error-button">
          Volver al inicio
        </Link>
      </div>
    </div>
  );
};
export default ErrorLayout;