import React, { useEffect, useState } from 'react';
import RoleForm from './RoleForm';
import '../../css/roleList.css';
import apiService from '../../shared/services/ApiService';

const RoleList = () => {
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [showForm, setShowForm] = useState(false);
    const URL = process.env.REACT_APP_URL_LOCAL_HOST;
    
    useEffect(() => {
        fetchRoles();
    }, []);
    
    const fetchRoles = async () => {
        setIsLoading(true);
        try {
            const response = await apiService.get(`/api/roles`);
            setRoles(response.data);
        } catch (error) {
            console.error("Error fetching roles:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleDelete = async (id, name) => {
        if (window.confirm(`¿Estás seguro que deseas eliminar el rol "${name}"?`)) {
            try {
                await apiService.delete(`/api/roles/${id}`);
                fetchRoles();
            } catch (error) {
                console.error("Error deleting role:", error);
            }
        }
    };
    
    const handleEdit = (role) => {
        setSelectedRole(role);
        setShowForm(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    
    const handleSubmit = async (role) => {
        setIsLoading(true);
        try {
            if (selectedRole) {
                await apiService.put(`/api/roles/${selectedRole._id}`, role);
            } else {
                await apiService.post(`/api/roles`, role);
            }
            setSelectedRole(null);
            setShowForm(false);
            fetchRoles();
        } catch (error) {
            console.error("Error saving role:", error);
            setIsLoading(false);
        }
    };

    const filteredRoles = roles.filter(role => 
        role.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        (role.description && role.description.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    
    return (
        <div className="role-list-container">
            <h2 className="role-title">Gestión de Roles</h2>
            
            <div className="role-controls">
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Buscar rol..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>
                {!showForm && (
                    <button 
                        className="add-button" 
                        onClick={() => {
                            setSelectedRole(null);
                            setShowForm(true);
                        }}
                    >
                        <span className="button-icon">+</span> Nuevo Rol
                    </button>
                )}
            </div>
            
            {showForm && (
                <div className="form-container">
                    <RoleForm 
                        onSubmit={handleSubmit} 
                        role={selectedRole} 
                        onCancel={() => {
                            setSelectedRole(null);
                            setShowForm(false);
                        }}
                    />
                </div>
            )}
            
            {isLoading ? (
                <div className="loading-spinner">
                    <div className="spinner"></div>
                    <p>Cargando roles...</p>
                </div>
            ) : (
                <>
                    {filteredRoles.length > 0 ? (
                        <div className="role-list-wrapper">
                            <ul className="role-list">
                                {filteredRoles.map((role) => (
                                    <li key={role._id} className="role-item">
                                        <div className="role-info">
                                            <h3 className="role-name">{role.name}</h3>
                                            {role.description && (
                                                <p className="role-description">{role.description}</p>
                                            )}
                                        </div>
                                        <div className="role-action-buttons">
                                            <button 
                                                onClick={() => handleEdit(role)} 
                                                className="edit-button"
                                                aria-label={`Editar ${role.name}`}
                                            >
                                                Editar
                                            </button>
                                            <button 
                                                onClick={() => handleDelete(role._id, role.name)} 
                                                className="delete-button"
                                                aria-label={`Eliminar ${role.name}`}
                                            >
                                                Eliminar
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div className="no-results">
                            <p>No se encontraron roles{searchTerm ? ` que coincidan con "${searchTerm}"` : ''}.</p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default RoleList;