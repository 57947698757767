import { useState, useEffect } from 'react';
import HistoryDashboard from '../dashboard/HistoryDashboard';
import '../../css/SupervisorDashboard.css';
import Cookies from 'js-cookie';
import apiservice from "../../shared/services/ApiService";
import { toast } from 'react-toastify';

const SupervisorDashboard = () => {
    const [sectors, setSectors] = useState([]);
    const [assignedBrigade, setAssignedBrigade] = useState(null);
    const [brigadeMembers, setBrigadeMembers] = useState([]);
    const [selectedSectorId, setSelectedSectorId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const [comment, setComment] = useState('');
    const userRole = Cookies.get('role');
    const userId = Cookies.get('userId');
    const [showSectorDetails, setShowSectorDetails] = useState(false);

    const [newScheduledDate, setNewScheduledDate] = useState('');
    const [updateHistory, setUpdateHistory] = useState(false);

    // Cargar los sectores asignados al supervisor
    const fetchSectors = async () => {
        try {
            const response = await apiservice.get(`/api/sectores`);
            const allSectors = response.data;
           

            if (userRole === 'Administrador') {
                setSectors(allSectors);
            } else {
                // Filtrar los sectores donde la brigada tenga al supervisor en `members`
                const sectorsForSupervisor = allSectors.filter(sector =>
                    sector.assignedBrigade && sector.assignedBrigade.members.includes(userId)
                );

                setSectors(sectorsForSupervisor);
            }
          
        } catch (error) {
            console.error('Error al obtener sectores:', error);
            toast.error('Error al cargar los sectores');
        }
    };

    

    useEffect(() => {
        fetchSectors();
       
       
    }, [userRole, userId]);

    // Cargar los miembros de la brigada asignada al sector
    const fetchBrigadeMembers = async (brigadeId) => {
        try {
            const response = await apiservice.get(`/api/brigadas/${brigadeId}`);
            setAssignedBrigade(response.data);
            setBrigadeMembers(response.data.members);  // Miembros de la brigada
        } catch (error) {
            console.error('Error al obtener miembros de la brigada:', error);
            toast.error('Error al cargar los miembros de la brigada');
        }
    };

    // Seleccionar un sector y cargar sus detalles
    const handleSelectSector = (sectorId) => {
        setSelectedSectorId(sectorId);
        const selectedSector = sectors.find(sector => sector._id === sectorId);

        if (selectedSector?.assignedBrigade) {
            fetchBrigadeMembers(selectedSector.assignedBrigade._id);
        } else {
            setAssignedBrigade(null);
            setBrigadeMembers([]);
        }
        
        // Mostrar los detalles del sector y ocultar la vista de tarjetas
        setShowSectorDetails(true);
    };

    // Volver a la vista de tarjetas
    const handleBackToCards = () => {
        setShowSectorDetails(false);
    };

    // Abrir el modal de edición de estado
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Cerrar el modal de edición de estado
    const closeModal = () => {
        setIsModalOpen(false);
        setNewStatus('');
        setComment('');
    };

    const handleUpdateSector = async () => {
        try {
            await apiservice.put(`/api/sectores/${selectedSectorId}`, {
                status: newStatus,
                comment: comment,
                scheduledDate: newScheduledDate || null,
            });

            closeModal();
            setSectors((prevSectors) =>
                prevSectors.map((sector) =>
                    sector._id === selectedSectorId
                        ? { ...sector, status: newStatus, comment: comment, scheduledDate: newScheduledDate || sector.scheduledDate }
                        : sector
                )
            );

            // Mostrar notificación de éxito
            toast.success('Estado del sector actualizado correctamente');
            
            // Forzar la actualización del historial
            setUpdateHistory((prev) => !prev);
        } catch (error) {
            console.error('Error al actualizar el sector:', error);
            toast.error('Error al actualizar el estado del sector');
        }
    };

    // Aceptar asignación de sector
    const handleAcceptAssignment = async (sectorId) => {
        try {
            await apiservice.put(`/api/sectores/${sectorId}/accept-assignment`);
            // Actualizar el estado del sector localmente
            setSectors((prevSectors) =>
                prevSectors.map((sector) =>
                    sector._id === sectorId
                        ? { ...sector, assignmentAccepted: true }
                        : sector
                )
            );
            
            // Mostrar notificación de éxito
            toast.success('Asignación aceptada correctamente');
            
            // Actualizar historial
            setUpdateHistory((prev) => !prev);
        } catch (error) {
            console.error('Error al aceptar la asignación:', error);
            toast.error('Error al aceptar la asignación');
        }
    };

    // Rechazar/negar asignación de sector
    const handleRejectAssignment = async (sectorId) => {
        try {
            await apiservice.put(`/api/sectores/${sectorId}/reject-assignment`);
            
            // Actualizar el estado del sector localmente
            setSectors((prevSectors) =>
                prevSectors.map((sector) =>
                    sector._id === sectorId
                        ? { ...sector, assignmentAccepted: false }
                        : sector
                )
            );
            
            // Mostrar notificación de éxito
            toast.success('Asignación rechazada correctamente');
            
            // Actualizar historial
            setUpdateHistory((prev) => !prev);
        } catch (error) {
            console.error('Error al rechazar la asignación:', error);
            toast.error('Error al rechazar la asignación');
        }
    };

    // Obtener el status color
    const getStatusColor = (status) => {
        switch (status) {
            case 'Pendiente':
                return '#f44336'; // Amarillo
            case 'Proceso':
                return '#FFC107'; // Azul
            case 'Listo':
                return '#4CAF50'; // Verde
            default:
                return '#9E9E9E'; // Gris por defecto
        }
    };

    return (
        <div className="dashboard-container">
            <h2 className="dashboard-title">Panel del Supervisor</h2>

            {/* Vista de tarjetas (solo se muestra si no hay un sector seleccionado) */}
            {!showSectorDetails && (
                <div className="sector-cards-container">
                    {sectors.map(sector => (
                        <div 
                            key={sector._id} 
                            className="sector-card"
                            onClick={() => handleSelectSector(sector._id)}
                        >
                            <div className="sector-card-header">
                                <div 
                                    className="status-indicator" 
                                    style={{ backgroundColor: getStatusColor(sector.status) }}
                                ></div>
                                <h3 className="sector-name">{sector.name}</h3>
                            </div>
                            <div className="sector-card-body">
                                <p className="sector-status">Estado: {sector.status || 'No definido'}</p>
                                {sector.scheduledDate && (
                                    <p className="scheduled-date">Fecha: {new Date(sector.scheduledDate).toLocaleDateString()}</p>
                                )}
                            </div>
                            {sector.assignmentAccepted ? (
                                <button 
                                    className="reject-assignment-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleRejectAssignment(sector._id);
                                    }}
                                >
                                    Rechazar Asignación
                                </button>
                            ) : (
                                <button 
                                    className="accept-assignment-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleAcceptAssignment(sector._id);
                                    }}
                                >
                                    Aceptar Asignación
                                </button>
                            )}
                        </div>
                    ))}
                </div>
            )}

            {/* Vista de detalles del sector seleccionado */}
            {showSectorDetails && selectedSectorId && (
                <div className="selected-sector-details">
                    {/* Botón para volver atrás */}
                    <button className="back-btn" onClick={handleBackToCards}>
                        <i className="fas fa-arrow-left"></i> Volver a Sectores
                    </button>
                    
                    {/* Información del sector seleccionado */}
                    <div className="sector-header">
                        <h3 className="sector-title">
                            {sectors.find(s => s._id === selectedSectorId)?.name}
                        </h3>
                        <div className="sector-actions">
                            {sectors.find(s => s._id === selectedSectorId)?.assignmentAccepted ? (
                                <button 
                                    className="reject-assignment-btn" 
                                    onClick={() => handleRejectAssignment(selectedSectorId)}
                                >
                                    Rechazar Asignación
                                </button>
                            ) : (
                                <button 
                                    className="accept-assignment-btn" 
                                    onClick={() => handleAcceptAssignment(selectedSectorId)}
                                >
                                    Aceptar Asignación
                                </button>
                            )}
                            <button className="edit-status-btn" onClick={openModal}>
                                Editar Estado del Sector
                            </button>
                        </div>
                    </div>
                    
                    {assignedBrigade && (
                        <div className="brigade-info">
                            <h3>Brigada Asignada: {assignedBrigade.name}</h3>
                            <div className="brigade-members-cards">
                                {brigadeMembers.map((member) => (
                                    <div key={member._id} className="member-card">
                                        <div className="member-avatar">
                                            {member.username.charAt(0).toUpperCase()}
                                        </div>
                                        <span className="member-name">{member.username}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Historial del sector */}
                    <div className="sector-history-container">
                        <h3>Historial del Sector</h3>
                        <HistoryDashboard sectorId={selectedSectorId} updateHistory={updateHistory} />
                    </div>

                    {/* Modal para editar el estado y comentario */}
                    {isModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <h3>Editar Estado del Sector</h3>
                                <div className="form-group">
                                    <label>Estado:</label>
                                    <select
                                        value={newStatus}
                                        onChange={(e) => setNewStatus(e.target.value)}
                                    >
                                        <option value="">Selecciona un estado</option>
                                        <option value="Pendiente">Pendiente</option>
                                        <option value="Proceso">En Proceso</option>
                                        <option value="Listo">Listo</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label>Comentario:</label>
                                    <textarea
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>Fecha Programada:</label>
                                    <input
                                        type="date"
                                        value={newScheduledDate}
                                        onChange={(e) => setNewScheduledDate(e.target.value)}
                                    />
                                </div>

                                <div className="modal-buttons">
                                    <button className="update-btn" onClick={handleUpdateSector}>Actualizar</button>
                                    <button className="close-btn" onClick={closeModal}>Cerrar</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SupervisorDashboard;