import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/userForm.css';
import Cookies from 'js-cookie';
import apiService from '../../shared/services/ApiService';

const UserForm = ({ onSubmit, user, onCancel }) => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        role: '',
        brigada: ''
    });
    const [roles, setRoles] = useState([]);
    const [brigadas, setBrigadas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    
    const token = Cookies.get('token');
    const URL = process.env.REACT_APP_URL_LOCAL_HOST;

    useEffect(() => {
        if (user) {
            setFormData({
                username: user.username || '',
                password: user.password || '',
                email: user.email || '',
                role: user.role || '',
                brigada: user.brigada || ''
            });
        } else {
            // Reset form when creating a new user
            setFormData({
                username: '',
                password: '',
                email: '',
                role: '',
                brigada: ''
            });
        }

        fetchRoles();
        fetchBrigadas();
    }, [user]);

    const fetchRoles = async () => {
        setLoading(true);
        try {
            const response = await apiService.get(`/api/roles`);
            setRoles(response.data);
        } catch (error) {
            console.error('Error al cargar roles:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBrigadas = async () => {
        setLoading(true);
        try {
            const response = await apiService.get(`/api/brigadas`);
            setBrigadas(response.data);
        } catch (error) {
            console.error('Error al cargar brigadas:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        
        // Clear error for this field when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: null
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        
        if (!formData.username.trim()) {
            newErrors.username = 'El nombre de usuario es requerido';
        }
        
        if (!user && !formData.password) {
            newErrors.password = 'La contraseña es requerida';
        } else if (formData.password && formData.password.length < 6) {
            newErrors.password = 'La contraseña debe tener al menos 6 caracteres';
        }
        
        if (!formData.email) {
            newErrors.email = 'El correo electrónico es requerido';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'El correo electrónico no es válido';
        }
        
        if (!formData.role) {
            newErrors.role = 'Debe seleccionar un rol';
        }
        
        if (!formData.brigada) {
            newErrors.brigada = 'Debe seleccionar una brigada';
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }
        
        onSubmit(formData);
    };

    if (loading && !roles.length && !brigadas.length) {
        return (
            <div className="form-loading">
                <div className="form-spinner"></div>
                <p>Cargando datos del formulario...</p>
            </div>
        );
    }

    return (
        <div className="user-form-container">
            <h3 className="form-title">{user ? 'Editar Usuario' : 'Crear Nuevo Usuario'}</h3>
            
            <form onSubmit={handleSubmit} className="user-form">
                <div className="form-group">
                    <label htmlFor="username" className="form-label">Nombre de Usuario</label>
                    <input
                        id="username"
                        name="username"
                        type="text"
                        placeholder="Ingrese nombre de usuario"
                        value={formData.username}
                        onChange={handleChange}
                        className={`form-input ${errors.username ? 'input-error' : ''}`}
                    />
                    {errors.username && <p className="error-message">{errors.username}</p>}
                </div>
                
                <div className="form-group">
                    <label htmlFor="password" className="form-label">
                        Contraseña {user && <span className="optional-text">(Dejar en blanco para mantener la actual)</span>}
                    </label>
                    <input
                        id="password"
                        name="password"
                        type="password"
                        placeholder={user ? "••••••••" : "Ingrese contraseña"}
                        value={formData.password}
                        onChange={handleChange}
                        className={`form-input ${errors.password ? 'input-error' : ''}`}
                        required={!user}
                    />
                    {errors.password && <p className="error-message">{errors.password}</p>}
                </div>
                
                <div className="form-group">
                    <label htmlFor="email" className="form-label">Correo Electrónico</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="ejemplo@correo.com"
                        value={formData.email}
                        onChange={handleChange}
                        className={`form-input ${errors.email ? 'input-error' : ''}`}
                    />
                    {errors.email && <p className="error-message">{errors.email}</p>}
                </div>
                
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="role" className="form-label">Rol</label>
                        <select
                            id="role"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            className={`form-select ${errors.role ? 'input-error' : ''}`}
                        >
                            <option value="">Seleccionar Rol</option>
                            {roles.map((role) => (
                                <option key={role._id} value={role._id}>
                                    {role.name}
                                </option>
                            ))}
                        </select>
                        {errors.role && <p className="error-message">{errors.role}</p>}
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="brigada" className="form-label">Brigada</label>
                        <select
                            id="brigada"
                            name="brigada"
                            value={formData.brigada}
                            onChange={handleChange}
                            className={`form-select ${errors.brigada ? 'input-error' : ''}`}
                        >
                            <option value="">Seleccionar Brigada</option>
                            {brigadas.map((brigada) => (
                                <option key={brigada._id} value={brigada._id}>
                                    {brigada.name}
                                </option>
                            ))}
                        </select>
                        {errors.brigada && <p className="error-message">{errors.brigada}</p>}
                    </div>
                </div>
                
                <div className="form-actions">
                    <button 
                        type="button" 
                        onClick={onCancel} 
                        className="cancel-button"
                    >
                        Cancelar
                    </button>
                    <button 
                        type="submit" 
                        className="submit-button"
                    >
                        {user ? 'Actualizar Usuario' : 'Crear Usuario'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UserForm;