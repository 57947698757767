import React, { useState } from 'react';
import apiService from './shared/services/ApiService';
import Cookies from 'js-cookie';
import './css/Login.css';
import { FaUser, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';

const Login = ({ onLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [touched, setTouched] = useState({ email: false, password: false });

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!email || !password) {
            setError('Por favor complete todos los campos');
            return;
        }
        
        setIsLoading(true);
        setError('');
        
        try {
            const response = await apiService.post(`/api/auth/login`, { email, password });
            Cookies.set('role', response.data.role.name, { expires: 1 });
            Cookies.set('userId', response.data.user, { expires: 1 });
            onLogin(response.data.role?.name);
        } catch (error) {
            console.error('Error de inicio de sesión:', error);
            setError(
                error.response?.data?.message || 
                'No se pudo iniciar sesión. Por favor verifique sus credenciales.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleBlur = (field) => {
        setTouched({ ...touched, [field]: true });
    };

    return (
        <div className="login-container">
            <div className="login-card">
                <div className="login-header">
                    <h2>Bienvenido</h2>
                    <p>Ingrese sus credenciales para acceder</p>
                </div>
                
                {error && (
                    <div className="error-message">
                        <p>{error}</p>
                    </div>
                )}
                
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="email">Correo electrónico</label>
                        <div className="input-container">
                            <FaUser className="input-icon" />
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={() => handleBlur('email')}
                                placeholder="ejemplo@correo.com"
                                className={touched.email && !email ? 'input-error' : ''}
                                disabled={isLoading}
                                required
                            />
                        </div>
                        {touched.email && !email && (
                            <span className="error-text">El correo es requerido</span>
                        )}
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="password">Contraseña</label>
                        <div className="input-container">
                            <FaLock className="input-icon" />
                            <input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={() => handleBlur('password')}
                                placeholder="Contraseña"
                                className={touched.password && !password ? 'input-error' : ''}
                                disabled={isLoading}
                                required
                            />
                            <button 
                                type="button" 
                                className="toggle-password"
                                onClick={togglePasswordVisibility}
                                aria-label={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </button>
                        </div>
                        {touched.password && !password && (
                            <span className="error-text">La contraseña es requerida</span>
                        )}
                    </div>
                    
                    <button 
                        type="submit" 
                        className={`login-button ${isLoading ? 'loading' : ''}`}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <span className="spinner"></span>
                        ) : (
                            'Iniciar Sesión'
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
