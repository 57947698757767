import React, { useEffect, useState } from 'react';
import BrigadeForm from './BrigadeForm';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import '../../css/brigade-list.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiService from '../../shared/services/ApiService';

const BrigadeList = () => {
  
    const [brigades, setBrigades] = useState([]);
    const [selectedBrigade, setSelectedBrigade] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(false);

    useEffect(() => {
        fetchBrigades();
    }, []);

    const fetchBrigades = async () => {
        setLoading(true);
        try {
            const response = await apiService.get(`/api/brigadas`);
            setBrigades(response.data);
            setError(null);
        } catch (err) {
            setError('Error al cargar las brigadas. Por favor, intente de nuevo.');
            toast.error('Error al cargar las brigadas');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            await apiService.delete(`/api/brigadas/${id}`);
            toast.success("Brigada eliminada con éxito");
            fetchBrigades();
        } catch (err) {
            toast.error("Error al eliminar la brigada");
        }
    };

    const handleEdit = (brigade) => {
        setSelectedBrigade(brigade);
        setIsFormVisible(true);
        // Scroll to form
        setTimeout(() => {
            document.querySelector('.brigade-form-wrapper').scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    const handleSubmit = async (brigade) => {
        try {
            if (selectedBrigade) {
                await apiService.put(`/api/brigadas/${selectedBrigade._id}`, brigade);
                toast.success("Brigada actualizada con éxito");
            } else {
                await apiService.post(`/api/brigadas`, brigade);
                toast.success("Brigada añadida con éxito");
            }
            setSelectedBrigade(null);
            setIsFormVisible(false);
            fetchBrigades();
        } catch (err) {
            toast.error(selectedBrigade ? "Error al actualizar la brigada" : "Error al crear la brigada");
        }
    };

    const handleCancelForm = () => {
        setSelectedBrigade(null);
        setIsFormVisible(false);
    };

    // Función para generar PDF individual
    const generatePDFForBrigade = (brigade) => {
        const doc = new jsPDF();
        doc.setFontSize(20);
        doc.text('Reporte de Brigada', 14, 16);
        doc.setFontSize(16);
        doc.text(`Brigada: ${brigade.name}`, 14, 30);
        
        let yPosition = 40;

        doc.setFontSize(14);
        if (brigade.members && brigade.members.length > 0) {
            brigade.members.forEach((member) => {
                doc.text(` - Miembro: ${member.username || member.name}`, 20, yPosition);
                yPosition += 10; // Mueve hacia abajo para el siguiente miembro
            });
        } else {
            doc.text(' - No hay miembros en esta brigada', 20, yPosition);
            yPosition += 10;
        }

        doc.save(`${brigade.name}_report.pdf`);
        toast.info(`Reporte PDF generado: ${brigade.name}`);
    };

    // Función para generar Excel
    const generateExcel = () => {
        const ws = XLSX.utils.json_to_sheet(brigades.map(brigade => ({
            Name: brigade.name,
            Members: brigade.members ? brigade.members.map(member => member.username).join(', ') : 'N/A',
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Brigadas');
        XLSX.writeFile(wb, 'brigades.xlsx');
        toast.info("Reporte Excel generado");
    };

    // Función para generar CSV
    const generateCSV = () => {
        const csvData = brigades.map(brigade => ({
            Name: brigade.name,
            Members: brigade.members ? brigade.members.map(member => member.username).join(', ') : 'N/A',
        }));

        const csvRows = [
            ['Name', 'Members'], // Cabeceras
            ...csvData.map(brigade => [brigade.Name, brigade.Members]),
        ];

        const csvString = csvRows.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'brigades.csv');
        a.click();
        toast.info("Reporte CSV generado");
    };

    // Pagination logic
    const filteredBrigades = brigades.filter(brigade => 
        brigade.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentBrigades = filteredBrigades.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredBrigades.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="brigade-container">
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
            
            <div className="brigade-header">
                <h2 className="brigade-title">Gestión de Brigadas</h2>
                <div className="brigade-actions-top">
                    <button 
                        className={`btn-add ${isFormVisible ? 'active' : ''}`} 
                        onClick={() => setIsFormVisible(!isFormVisible)}
                    >
                        <i className={`fas ${isFormVisible ? 'fa-times' : 'fa-plus'}`}></i> 
                        <span>{isFormVisible ? 'Cancelar' : 'Nueva Brigada'}</span>
                    </button>
                    
                    <div className="export-buttons">
                        <button className="btn-export" onClick={generateExcel}>
                            <i className="fas fa-file-excel"></i> Excel
                        </button>
                        <button className="btn-export" onClick={generateCSV}>
                            <i className="fas fa-file-csv"></i> CSV
                        </button>
                    </div>
                </div>
            </div>
            
            {isFormVisible && (
                <div className="brigade-form-wrapper card">
                    <h3 className="form-title">{selectedBrigade ? 'Editar Brigada' : 'Nueva Brigada'}</h3>
                    <BrigadeForm 
                        onSubmit={handleSubmit} 
                        brigade={selectedBrigade} 
                        onCancel={handleCancelForm} 
                    />
                </div>
            )}
            
            <div className="brigade-list-section card">
                <div className="list-header">
                    <div className="list-controls">
                        <div className="search-box">
                            <i className="fas fa-search"></i>
                            <input 
                                type="text" 
                                placeholder="Buscar brigadas..." 
                                value={searchTerm}
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                        <select 
                            className="items-per-page" 
                            value={itemsPerPage} 
                            onChange={(e) => {
                                setItemsPerPage(Number(e.target.value));
                                setCurrentPage(1);
                            }}
                        >
                            <option value="5">5 por página</option>
                            <option value="10">10 por página</option>
                            <option value="20">20 por página</option>
                        </select>
                    </div>
                </div>
                
                {loading ? (
                    <div className="loading-container">
                        <div className="spinner"></div>
                        <p>Cargando brigadas...</p>
                    </div>
                ) : error ? (
                    <div className="error-message">
                        <i className="fas fa-exclamation-triangle"></i>
                        <p>{error}</p>
                        <button className="retry-button" onClick={fetchBrigades}>
                            <i className="fas fa-redo"></i> Reintentar
                        </button>
                    </div>
                ) : (
                    <>
                        {currentBrigades.length > 0 ? (
                            <ul className="brigade-items">
                                {currentBrigades.map((brigade) => (
                                    <li key={brigade._id} className="brigade-item">
                                        <div className="brigade-content">
                                            <h4 className="brigade-name">{brigade.name}</h4>
                                            {brigade.description && (
                                                <p className="brigade-description">{brigade.description}</p>
                                            )}
                                            <div className="members-info">
                                                <span className="members-count">
                                                    <i className="fas fa-users"></i> 
                                                    {brigade.members ? brigade.members.length : 0} miembros
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div className="brigade-buttons">
                                            <button 
                                                onClick={() => handleEdit(brigade)} 
                                                className="btn-action btn-edit" 
                                                title="Editar"
                                            >
                                                <i className="fas fa-edit"></i>
                                            </button>
                                            <button 
                                                onClick={() => generatePDFForBrigade(brigade)} 
                                                className="btn-action btn-pdf" 
                                                title="Generar PDF"
                                            >
                                                <i className="fas fa-file-pdf"></i>
                                            </button>
                                            <button 
                                                onClick={() => {
                                                    if(window.confirm('¿Está seguro que desea eliminar esta brigada?')) {
                                                        handleDelete(brigade._id);
                                                    }
                                                }} 
                                                className="btn-action btn-delete" 
                                                title="Eliminar"
                                            >
                                                <i className="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="empty-state">
                                <i className="fas fa-info-circle"></i>
                                <p>
                                    {searchTerm 
                                        ? `No se encontraron brigadas que coincidan con "${searchTerm}"` 
                                        : "No hay brigadas registradas"}
                                </p>
                                {searchTerm && (
                                    <button className="clear-search" onClick={() => setSearchTerm('')}>
                                        Limpiar búsqueda
                                    </button>
                                )}
                            </div>
                        )}
                        
                        {totalPages > 1 && (
                            <div className="pagination">
                                <button 
                                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                    className="page-btn"
                                >
                                    <i className="fas fa-chevron-left"></i>
                                </button>
                                
                                <div className="page-numbers">
                                    {Array.from({ length: totalPages }, (_, i) => i + 1)
                                        .filter(num => 
                                            num === 1 || 
                                            num === totalPages || 
                                            (num >= currentPage - 1 && num <= currentPage + 1)
                                        )
                                        .map((number, index, array) => {
                                            // Add ellipsis if needed
                                            if (index > 0 && array[index - 1] !== number - 1) {
                                                return (
                                                    <React.Fragment key={`ellipsis-${number}`}>
                                                        <span className="ellipsis">...</span>
                                                        <button 
                                                            onClick={() => paginate(number)}
                                                            className={`page-btn ${currentPage === number ? 'active' : ''}`}
                                                        >
                                                            {number}
                                                        </button>
                                                    </React.Fragment>
                                                );
                                            }
                                            return (
                                                <button 
                                                    key={number}
                                                    onClick={() => paginate(number)}
                                                    className={`page-btn ${currentPage === number ? 'active' : ''}`}
                                                >
                                                    {number}
                                                </button>
                                            );
                                        })
                                    }
                                </div>
                                
                                <button 
                                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                    disabled={currentPage === totalPages}
                                    className="page-btn"
                                >
                                    <i className="fas fa-chevron-right"></i>
                                </button>
                            </div>
                        )}
                        
                        <div className="pagination-info">
                            Mostrando {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, filteredBrigades.length)} de {filteredBrigades.length} brigadas
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default BrigadeList;