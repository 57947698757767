import React, { useState, useEffect, useRef } from 'react';
import '../../css/circunscripcionForm.css';

const CircunscripcionForm = ({ onSubmit, circunscripcion, isOpen, onClose }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    
    // Referencia para enfocar el primer campo al abrir
    const nameInputRef = useRef(null);
    
    // Referencia para el click fuera del modal
    const modalRef = useRef(null);

    useEffect(() => {
        if (circunscripcion) {
            setName(circunscripcion.name || '');
            setDescription(circunscripcion.description || '');
        } else {
            setName('');
            setDescription('');
        }
        
        // Reset errores
        setErrors({});
        
        // Enfocar el campo de nombre cuando se abre el modal
        if (isOpen && nameInputRef.current) {
            setTimeout(() => {
                nameInputRef.current.focus();
            }, 100);
        }
        
        // Manejar tecla Escape para cerrar el modal
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };
        
        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [circunscripcion, isOpen, onClose]);
    
    // Manejar click fuera del modal para cerrar
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };
        
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, onClose]);

    const validateForm = () => {
        const newErrors = {};
        
        // Validar nombre
        if (!name.trim()) {
            newErrors.name = 'El nombre es obligatorio';
        } else if (name.trim().length < 3) {
            newErrors.name = 'El nombre debe tener al menos 3 caracteres';
        } else if (name.trim().length > 100) {
            newErrors.name = 'El nombre no puede exceder los 100 caracteres';
        }
        
        // Validar descripción (opcional, pero con restricciones si se proporciona)
        if (description && description.length > 500) {
            newErrors.description = 'La descripción no puede exceder los 500 caracteres';
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validar formulario
        if (!validateForm()) {
            return;
        }
        
        setIsSubmitting(true);
        
        try {
            await onSubmit({ 
                name: name.trim(), 
                description: description.trim() 
            });
            
            // El onClose es manejado por el componente padre después de una operación exitosa
        } catch (error) {
            console.error("Error en el formulario:", error);
            setIsSubmitting(false);
        }
    };
    
    const handleCancel = () => {
        // Si hay cambios, confirmar antes de cerrar
        const hasChanges = (circunscripcion && (
            name !== circunscripcion.name || 
            description !== circunscripcion.description
        )) || (!circunscripcion && (name || description));
        
        if (hasChanges) {
            if (window.confirm('¿Estás seguro de que quieres cancelar? Los cambios no guardados se perderán.')) {
                onClose();
            }
        } else {
            onClose();
        }
    };

    if (!isOpen) return null;

    const characterCount = description.length;
    const maxCharacters = 500;

    return (
        <div className="modal-overlay">
            <div className="modal-content" ref={modalRef}>
                <button 
                    className="modal-close" 
                    onClick={handleCancel}
                    aria-label="Cerrar"
                    title="Cerrar"
                >
                    &times;
                </button>
                
                <h2>{circunscripcion ? 'Editar Circunscripción' : 'Nueva Circunscripción'}</h2>
                
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="name" className="required-field">Nombre</label>
                        <input
                            id="name"
                            ref={nameInputRef}
                            type="text"
                            placeholder="Ingrese nombre de la circunscripción"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            disabled={isSubmitting}
                            maxLength={100}
                            aria-required="true"
                            aria-invalid={errors.name ? "true" : "false"}
                            aria-describedby={errors.name ? "name-error" : undefined}
                        />
                        {errors.name && (
                            <div className="form-error" id="name-error">{errors.name}</div>
                        )}
                        <div className="char-counter">
                            {name.length}/100 caracteres
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="description">
                            Descripción
                            <span className="tooltip">
                                <span className="tooltip-icon">?</span>
                                <span className="tooltip-text">
                                    Ingrese información adicional sobre la circunscripción.
                                </span>
                            </span>
                        </label>
                        <textarea
                            id="description"
                            placeholder="Describa la circunscripción (opcional)"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            disabled={isSubmitting}
                            maxLength={maxCharacters}
                            aria-invalid={errors.description ? "true" : "false"}
                            aria-describedby={errors.description ? "description-error" : undefined}
                        />
                        {errors.description && (
                            <div className="form-error" id="description-error">{errors.description}</div>
                        )}
                        <div className="char-counter" style={{ 
                            color: characterCount > maxCharacters * 0.8 ? 
                                (characterCount > maxCharacters * 0.9 ? 'var(--danger-color)' : 'orange') : 
                                'var(--text-muted)'
                        }}>
                            {characterCount}/{maxCharacters} caracteres
                        </div>
                    </div>
                    
                    <div className="form-actions">
                        <button 
                            type="button" 
                            className="btn-cancel-form" 
                            onClick={handleCancel}
                            disabled={isSubmitting}
                        >
                            Cancelar
                        </button>
                        <button 
                            type="submit" 
                            className={isSubmitting ? "loading-button" : ""}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Guardando...' : (circunscripcion ? 'Actualizar' : 'Guardar')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CircunscripcionForm;