import axios from 'axios';
import EventEmitter from 'events';
export const eventEmitter = new EventEmitter();

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_LOCAL_HOST,
  withCredentials: true,
});
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      eventEmitter.emit('apiError', error.response.status);
    }
    return Promise.reject(error);
  }
);
const apiService = {
    get: (url) => api.get(url),
    post: (url, data) => api.post(url, data),
    put: (url, data) => api.put(url, data),
    patch: (url, data) => api.patch(url, data),
    delete: (url) => api.delete(url),
  };


  export default apiService;