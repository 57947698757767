import React, { useState, useEffect } from 'react';
import '../../css/roleForm.css';

const RoleForm = ({ onSubmit, role, onCancel }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (role) {
            setFormData({
                name: role.name || '',
                description: role.description || ''
            });
        } else {
            // Reset form when creating a new role
            setFormData({
                name: '',
                description: ''
            });
        }
    }, [role]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        
        // Clear error for this field when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: null
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        
        if (!formData.name.trim()) {
            newErrors.name = 'El nombre del rol es requerido';
        }
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!validateForm()) {
            return;
        }
        
        onSubmit(formData);
    };

    return (
        <div className="role-form-wrapper">
            <h3 className="form-title">{role ? 'Editar Rol' : 'Crear Nuevo Rol'}</h3>
            
            <form onSubmit={handleSubmit} className="role-form">
                <div className="form-group">
                    <label htmlFor="name" className="form-label">Nombre del Rol</label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Ingrese nombre del rol"
                        value={formData.name}
                        onChange={handleChange}
                        className={`form-input ${errors.name ? 'input-error' : ''}`}
                    />
                    {errors.name && <p className="error-message">{errors.name}</p>}
                </div>
                
                <div className="form-group">
                    <label htmlFor="description" className="form-label">Descripción del Rol</label>
                    <textarea
                        id="description"
                        name="description"
                        placeholder="Ingrese descripción del rol y sus permisos"
                        value={formData.description}
                        onChange={handleChange}
                        className="form-textarea"
                        rows="4"
                    />
                </div>
                
                <div className="form-actions">
                    <button 
                        type="button" 
                        onClick={onCancel} 
                        className="cancel-button"
                    >
                        Cancelar
                    </button>
                    <button 
                        type="submit" 
                        className="submit-button"
                    >
                        {role ? 'Actualizar Rol' : 'Crear Rol'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default RoleForm;