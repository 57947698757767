"use client"

import { useEffect, useRef, useState } from "react"
import { Map, View } from "ol"
import TileLayer from "ol/layer/Tile"
import OSM from "ol/source/OSM"
import { fromLonLat, toLonLat } from "ol/proj"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import Feature from "ol/Feature"
import LineString from "ol/geom/LineString"
import Style from "ol/style/Style"
import Stroke from "ol/style/Stroke"
import Point from "ol/geom/Point"
import CircleStyle from "ol/style/Circle"
import Fill from "ol/style/Fill"
import Overlay from "ol/Overlay"
import { getDistance } from "ol/sphere"
import Text from "ol/style/Text"

import "../../css/mapa.css"
import apiService from "../../shared/services/ApiService"
import SectorForm from "../sectores/SectorForm"

// Constantes
const LATITUD_CENTRO = 18.4861
const LONGITUD_CENTRO = -69.9312
const ZOOM = 15

// Colores de la aplicación
const COLORS = {
    primary: "#3498db",
    primaryHover: "#2980b9",
    danger: "#e74c3c",
    dangerHover: "#c0392b",
    success: "#2ecc71",
    successHover: "#27ae60",
    bgLight: "#f8f9fa",
    accent1: "#008f45",
    accent2: "#0057A6",
    pending: "#e74c3c",
    inProcess: "#f39c12",
    completed: "#2ecc71",
    white: "#ffffff",
    black: "#333333",
    border: "#e0e0e0",
    shadow: "rgba(0, 0, 0, 0.1)",
    userLocation: "#3498db", // Color for user location marker
    newMarker: "#9b59b6", // Color para marcadores nuevos
}

const Mapa = () => {
    const mapaRef = useRef()
    const mapaInstancia = useRef()
    const searchRef = useRef(null)
    const [ultimaCapa, setUltimaCapa] = useState(null)
    const [sectors, setSectors] = useState([])
    const [posicionActual, setPosicionActual] = useState(null)
    const [userLocationMarker, setUserLocationMarker] = useState(null)
    const [busqueda, setBusqueda] = useState("")
    const [sugerencias, setSugerencias] = useState([])
    const [cargando, setCargando] = useState(true)
    const [sectorSeleccionado, setSectorSeleccionado] = useState(null)
    const [brigades, setBrigades] = useState([])
    const [circunscripciones, setCircunscripciones] = useState([])
    const [infoOverlay, setInfoOverlay] = useState(null)
    const [customMarker, setCustomMarker] = useState(null)
    const [showMarkerForm, setShowMarkerForm] = useState(false)
    const [markerInfo, setMarkerInfo] = useState({ title: "", description: "" })
    const [markerPosition, setMarkerPosition] = useState(null)
    const [showSectorForm, setShowSectorForm] = useState(false);
    const [newSectorCoordinates, setNewSectorCoordinates] = useState(null);

    // Estados para el modo de edición
    const [modoEdicion, setModoEdicion] = useState(false)
    const [coordenadasTemporal, setCoordenadasTemporal] = useState([])
    const [marcadoresLayer, setMarcadoresLayer] = useState(null)
    const [lineaLayer, setLineaLayer] = useState(null)
    const [modalFormularioAbierto, setModalFormularioAbierto] = useState(false)
    const [nuevoSector, setNuevoSector] = useState(null)

    useEffect(() => {
        if (!mapaInstancia.current) return

        // Crear y asignar el elemento HTML para el overlay
        const overlayDiv = document.createElement("div")
        overlayDiv.className = "sector-info-overlay"

        const overlay = new Overlay({
            element: overlayDiv,
            positioning: "bottom-center",
            offset: [0, -10],
            stopEvent: true,
        })

        // Agregar el overlay al mapa
        mapaInstancia.current.addOverlay(overlay)
        setInfoOverlay(overlay)

        return () => {
            if (mapaInstancia.current && overlay) {
                mapaInstancia.current.removeOverlay(overlay)
            }
        }
    }, [])

    // Obtener brigadas y circunscripciones para el formulario
    const fetchBrigadesAndCircunscripciones = async () => {
        try {
            const [brigadesResponse, circResponse] = await Promise.all([
                apiService.get(`/api/brigadas`),
                apiService.get(`/api/circunscripciones`),
            ])
            setBrigades(brigadesResponse.data)
            setCircunscripciones(circResponse.data)
        } catch (error) {
            console.error("Error al obtener datos:", error)
        }
    }

    useEffect(() => {
        fetchBrigadesAndCircunscripciones()
    }, [])

    // Inicializar capas de edición
    useEffect(() => {
        if (!mapaInstancia.current) return

        // Capa para marcadores de edición
        const markerSource = new VectorSource()
        const markerLayerInstance = new VectorLayer({
            source: markerSource,
            zIndex: 100,
        })
        mapaInstancia.current.addLayer(markerLayerInstance)
        setMarcadoresLayer(markerLayerInstance)

        // Capa para la línea que conecta los marcadores
        const lineSource = new VectorSource()
        const lineLayerInstance = new VectorLayer({
            source: lineSource,
            zIndex: 99,
            style: new Style({
                stroke: new Stroke({
                    color: COLORS.newMarker,
                    width: 2,
                    lineDash: [5, 5],
                }),
            }),
        })
        mapaInstancia.current.addLayer(lineLayerInstance)
        setLineaLayer(lineLayerInstance)

        return () => {
            if (mapaInstancia.current) {
                mapaInstancia.current.removeLayer(markerLayerInstance)
                mapaInstancia.current.removeLayer(lineLayerInstance)
            }
        }
    }, [])

    const handleMouseMove = (event) => {
        if (modoEdicion) return // Deshabilitar hover en modo edición

        const map = mapaInstancia.current
        const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => feature)

        if (feature) {
            const featureCoordinates = feature.getGeometry().getCoordinates()
            const featureLonLat = toLonLat(featureCoordinates)

            const sector = sectors.find((s) => {
                return s.coordinates.some((coord) => {
                    const sectorLonLat = coord
                    const distance = getDistance(sectorLonLat, featureLonLat)
                    return distance < 50
                })
            })

            if (sector) {
                document.body.style.cursor = "pointer"
                mostrarInfoSector(sector, featureLonLat)
            } else {
                document.body.style.cursor = "default"
                if (infoOverlay) {
                    infoOverlay.setPosition(undefined)
                }
            }
        } else {
            document.body.style.cursor = modoEdicion ? "crosshair" : "default"
            if (infoOverlay) {
                infoOverlay.setPosition(undefined)
            }
        }
    }

    const mostrarInfoSector = (sector, coordenadas) => {
        if (!infoOverlay) return

        const overlayElement = infoOverlay.getElement()
        if (!overlayElement) return

        // Determinar el color según el estado
        let statusColor
        switch (sector.status) {
            case "Pendiente":
                statusColor = COLORS.pending
                break
            case "Proceso":
                statusColor = COLORS.inProcess
                break
            case "Listo":
                statusColor = COLORS.completed
                break
            default:
                statusColor = COLORS.success
                break
        }

        overlayElement.innerHTML = `
            <div class="popup-container">
                <div class="popup-header" style="background-color: ${statusColor};">
                    <h3>${sector.name}</h3>
                </div>
                <div class="popup-body">
                    <p><strong>Estado:</strong> ${sector.status}</p>
                    ${sector.description ? `<p><strong>Descripción:</strong> ${sector.description}</p>` : ""}
                    <button class="popup-button">Ver detalles</button>
                </div>
            </div>
        `

        // Añadir el evento al botón
        const button = overlayElement.querySelector(".popup-button")
        if (button) {
            button.onclick = () => {
                setSectorSeleccionado(sector)
            }
        }

        infoOverlay.setPosition(fromLonLat(coordenadas))
    }

    const handleMapClick = (event) => {
        if (modoEdicion) {
            // En modo edición, agregar marcador
            const coordenadas = mapaInstancia.current.getCoordinateFromPixel(event.pixel)
            const coordenadasLonLat = toLonLat(coordenadas)

            // Agregar a la lista de coordenadas temporales
            setCoordenadasTemporal([...coordenadasTemporal, coordenadasLonLat])

            // Agregar marcador al mapa
            agregarMarcadorTemporal(coordenadasLonLat)

            // Actualizar línea que conecta los puntos
            actualizarLineaTemporal([...coordenadasTemporal, coordenadasLonLat])
        } else {
            // En modo normal, seleccionar sector existente
            const feature = mapaInstancia.current.forEachFeatureAtPixel(event.pixel, (feature) => feature)

            if (feature) {
                const featureCoordinates = feature.getGeometry().getCoordinates()
                const featureLonLat = toLonLat(featureCoordinates)

                const sector = sectors.find((s) => {
                    return s.coordinates.some((coord) => {
                        const sectorLonLat = coord
                        const distance = getDistance(sectorLonLat, featureLonLat)
                        return distance < 50
                    })
                })

                if (sector) {
                    setSectorSeleccionado(sector)
                    mostrarInfoSector(sector, featureLonLat)
                }
            }
        }
    }

    const handleAddMarker = () => {
        // Toggle marker placement mode
        if (customMarker) {
            // If there's already a marker, remove it first
            const markerLayer = customMarker.getLayer();
            if (markerLayer) {
                mapaInstancia.current.removeLayer(markerLayer);
            }
            setCustomMarker(null);
            setShowMarkerForm(false);
        }

        // Set cursor to indicate marker placement mode
        document.body.style.cursor = "crosshair";

        // Add one-time click listener for marker placement
        const clickListener = (e) => {
            const coords = mapaInstancia.current.getCoordinateFromPixel(e.pixel);
            const lonLat = toLonLat(coords);

            // Create marker at clicked position
            createCustomMarker(lonLat);
            setMarkerPosition(lonLat);

            // Ahora mostramos el formulario de sector en lugar del formulario de marcador
            setNewSectorCoordinates([lonLat]);
            setShowSectorForm(true);

            // Reset cursor
            document.body.style.cursor = "default";

            // Remove this one-time listener
            mapaInstancia.current.un("click", clickListener);
        };

        // Add temporary click listener
        mapaInstancia.current.once("click", clickListener);
    };

    // Add this function to create the custom marker
    const createCustomMarker = (coordinates) => {
        if (!mapaInstancia.current) return

        // Remove existing marker if any
        if (customMarker && customMarker.getLayer()) {
            mapaInstancia.current.removeLayer(customMarker.getLayer())
        }

        // Create marker feature
        const point = new Feature({
            geometry: new Point(fromLonLat(coordinates)),
        })

        // Style for the marker
        point.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 8,
                    fill: new Fill({
                        color: "#e67e22", // Orange color for custom marker
                    }),
                    stroke: new Stroke({
                        color: COLORS.white,
                        width: 2,
                    }),
                }),
            }),
        )

        // Create layer for the marker
        const markerLayer = new VectorLayer({
            source: new VectorSource({
                features: [point],
            }),
            zIndex: 1000, // Ensure it's on top
        })

        // Add to map
        mapaInstancia.current.addLayer(markerLayer)

        // Save reference
        setCustomMarker({
            feature: point,
            getLayer: () => markerLayer,
        })
    }

    // Add this function to save marker info
    const saveMarkerInfo = () => {
        if (!customMarker || !markerPosition) return

        // Here you would typically save the marker to your database
        // For now, we'll just update the marker with the info

        // Update marker style to indicate it has info
        customMarker.feature.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 8,
                    fill: new Fill({
                        color: "#e67e22",
                    }),
                    stroke: new Stroke({
                        color: COLORS.white,
                        width: 2,
                    }),
                }),
                text: new Text({
                    text: markerInfo.title,
                    offsetY: -15,
                    fill: new Fill({
                        color: "#333",
                    }),
                    stroke: new Stroke({
                        color: "#fff",
                        width: 2,
                    }),
                }),
            }),
        )

        // Close the form
        setShowMarkerForm(false)

        // You could also add this marker to a state array to keep track of all custom markers
        console.log("Marker saved:", {
            position: markerPosition,
            title: markerInfo.title,
            description: markerInfo.description,
        })
    }

    const agregarMarcadorTemporal = (coordenadasLonLat) => {
        if (!marcadoresLayer) return

        const punto = new Feature({
            geometry: new Point(fromLonLat(coordenadasLonLat)),
        })

        punto.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 6,
                    fill: new Fill({
                        color: COLORS.newMarker,
                    }),
                    stroke: new Stroke({
                        color: COLORS.white,
                        width: 2,
                    }),
                }),
            }),
        )

        marcadoresLayer.getSource().addFeature(punto)
    }

    const actualizarLineaTemporal = (coordenadas) => {
        if (!lineaLayer || coordenadas.length < 2) return

        // Limpiar líneas existentes
        lineaLayer.getSource().clear()

        // Crear nueva línea con todos los puntos
        const lineaGeom = new LineString(coordenadas.map((coord) => fromLonLat(coord)))
        const lineaFeature = new Feature({
            geometry: lineaGeom,
        })

        lineaLayer.getSource().addFeature(lineaFeature)
    }

    const limpiarMarcadoresTemporales = () => {
        if (marcadoresLayer) {
            marcadoresLayer.getSource().clear()
        }
        if (lineaLayer) {
            lineaLayer.getSource().clear()
        }
        setCoordenadasTemporal([])
    }

    const toggleModoEdicion = () => {
        if (modoEdicion) {
            // Si está saliendo del modo edición
            if (coordenadasTemporal.length > 0) {
                // Si hay coordenadas, abrir formulario
                setNuevoSector({
                    coordinates: coordenadasTemporal,
                })
                setModalFormularioAbierto(true)
            } else {
                // Si no hay coordenadas, simplemente salir del modo
                setModoEdicion(false)
                limpiarMarcadoresTemporales()
            }
        } else {
            // Entrar en modo edición
            setModoEdicion(true)
            limpiarMarcadoresTemporales()
            setSectorSeleccionado(null)
        }
    }

    const handleGuardarSector = async (sectorData) => {
        try {
            setCargando(true)
            // Asegurar que las coordenadas temporales se incluyan en los datos
            const datosCompletos = {
                ...sectorData,
                coordinates: coordenadasTemporal,
            }

            // Enviar datos al servidor
            const response = await apiService.post("/api/sectores", datosCompletos)

            // Actualizar la lista de sectores
            fetchSectors()

            // Limpiar
            limpiarMarcadoresTemporales()
            setModoEdicion(false)
            setModalFormularioAbierto(false)
        } catch (error) {
            console.error("Error al guardar el sector:", error)
        } finally {
            setCargando(false)
        }
    }

    const cancelarNuevoSector = () => {
        setModalFormularioAbierto(false)
        setModoEdicion(false)
        limpiarMarcadoresTemporales()
    }

    useEffect(() => {
        if (!mapaInstancia.current) return

        // Evento de clic para el mapa
        mapaInstancia.current.on("click", handleMapClick)
        mapaInstancia.current.on("pointermove", handleMouseMove)

        return () => {
            if (mapaInstancia.current) {
                mapaInstancia.current.un("click", handleMapClick)
                mapaInstancia.current.un("pointermove", handleMouseMove)
            }
        }
    }, [sectors, infoOverlay, modoEdicion, coordenadasTemporal])

    const fetchSectors = async () => {
        setCargando(true)
        try {
            const response = await apiService.get(`/api/sectores`)
            const sortedSectors = response.data.sort((a, b) => a.name.localeCompare(b.name))
            setSectors(sortedSectors)
        } catch (error) {
            console.error("Error al obtener los sectores:", error)
        } finally {
            setCargando(false)
        }
    }

    // Función para crear o actualizar el marcador de ubicación del usuario
    const crearActualizarMarcadorUsuario = (coordenadas) => {
        if (!mapaInstancia.current) return

        // Eliminar marcador anterior si existe
        if (userLocationMarker && userLocationMarker.getLayer()) {
            mapaInstancia.current.removeLayer(userLocationMarker.getLayer())
        }

        // Crear nuevo marcador
        const punto = new Feature({
            geometry: new Point(fromLonLat(coordenadas)),
        })

        // Estilo personalizado para el marcador de usuario (círculo con pulsación)
        punto.setStyle(
            new Style({
                image: new CircleStyle({
                    radius: 8,
                    fill: new Fill({
                        color: COLORS.userLocation,
                    }),
                    stroke: new Stroke({
                        color: "white",
                        width: 2,
                    }),
                }),
            }),
        )

        // Crear capa para el marcador
        const capaUsuario = new VectorLayer({
            source: new VectorSource({
                features: [punto],
            }),
            zIndex: 999, // Asegurarse que aparezca por encima de otras capas
        })

        // Añadir la capa al mapa
        mapaInstancia.current.addLayer(capaUsuario)

        // Guardar referencia al marcador y su capa
        setUserLocationMarker({
            feature: punto,
            getLayer: () => capaUsuario,
        })

        // Añadir efecto de pulsación (opcional)
        // Este efecto se puede implementar con CSS en la hoja de estilos separada
        const markerElement = document.createElement("div")
        markerElement.className = "user-location-pulse"

        const pulseOverlay = new Overlay({
            element: markerElement,
            positioning: "center-center",
            offset: [0, 0],
            position: fromLonLat(coordenadas),
        })

        mapaInstancia.current.addOverlay(pulseOverlay)
    }

    const obtenerUbicacion = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords
                    const coordenadas = [longitude, latitude]
                    setPosicionActual(coordenadas)
                    mapaInstancia.current.getView().setCenter(fromLonLat(coordenadas))

                    // Crear o actualizar el marcador de ubicación del usuario
                    crearActualizarMarcadorUsuario(coordenadas)
                },
                (error) => {
                    console.error("Error al obtener la ubicación:", error)
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 10000,
                    timeout: 5000,
                },
            )
        } else {
            console.log("La geolocalización no está soportada en este navegador.")
        }
    }

    const dibujarSectoresEnMapa = () => {
        if (!mapaInstancia.current) return

        if (ultimaCapa) {
            mapaInstancia.current.removeLayer(ultimaCapa)
        }

        const features = sectors
            .filter((sector) => sector.coordinates && sector.coordinates.length > 0)
            .map((sector) => {
                const coordenadas = sector.coordinates.map((coord) => fromLonLat([coord[0], coord[1]]))

                if (coordenadas.length > 1) {
                    // Polilínea para sectores con múltiples coordenadas
                    const polilinea = new Feature({
                        geometry: new LineString(coordenadas),
                        sector: sector, // Guardamos el sector como propiedad
                    })

                    // Asignar color según estado
                    let strokeColor
                    switch (sector.status) {
                        case "Pendiente":
                            strokeColor = COLORS.pending
                            break
                        case "Proceso":
                            strokeColor = COLORS.inProcess
                            break
                        default:
                            strokeColor = COLORS.success
                            break
                    }

                    polilinea.setStyle(
                        new Style({
                            stroke: new Stroke({
                                color: strokeColor,
                                width: 3,
                                lineDash: sector.status === "Proceso" ? [5, 5] : undefined,
                            }),
                        }),
                    )

                    return polilinea
                } else {
                    // Marcador para sectores con una sola coordenada
                    const marcador = new Feature({
                        geometry: new Point(coordenadas[0]),
                        sector: sector,
                    })

                    // Color según estado
                    let marcadorColor
                    switch (sector.status) {
                        case "Pendiente":
                            marcadorColor = COLORS.pending
                            break
                        case "Proceso":
                            marcadorColor = COLORS.inProcess
                            break
                        default:
                            marcadorColor = COLORS.success
                            break
                    }

                    marcador.setStyle(
                        new Style({
                            image: new CircleStyle({
                                radius: 8,
                                fill: new Fill({ color: marcadorColor }),
                                stroke: new Stroke({
                                    color: COLORS.white,
                                    width: 2,
                                }),
                            }),
                        }),
                    )

                    return marcador
                }
            })

        const nuevaCapa = new VectorLayer({
            source: new VectorSource({ features: features }),
        })

        mapaInstancia.current.addLayer(nuevaCapa)
        setUltimaCapa(nuevaCapa)
    }

    const handleBusqueda = (e) => {
        const valor = e.target.value
        setBusqueda(valor)

        if (valor.length > 0) {
            const coincidencias = sectors.filter((sector) => sector.name.toLowerCase().includes(valor.toLowerCase()))
            setSugerencias(coincidencias)
        } else {
            setSugerencias([])
        }
    }

    const seleccionarSector = (sector) => {
        setBusqueda(sector.name)
        setSugerencias([])
        setSectorSeleccionado(sector)

        if (sector.coordinates && sector.coordinates.length) {
            const coordenadas = fromLonLat(sector.coordinates[0])
            mapaInstancia.current.getView().setCenter(coordenadas)
            mapaInstancia.current.getView().setZoom(17)
        }
    }

    const centrarMapa = () => {
        if (posicionActual) {
            mapaInstancia.current.getView().setCenter(fromLonLat(posicionActual))
            mapaInstancia.current.getView().setZoom(16)
        } else {
            obtenerUbicacion()
        }
    }

    const resetearVista = () => {
        mapaInstancia.current.getView().setCenter(fromLonLat([LONGITUD_CENTRO, LATITUD_CENTRO]))
        mapaInstancia.current.getView().setZoom(ZOOM)
        setSectorSeleccionado(null)
        setBusqueda("")
        setSugerencias([])
        if (infoOverlay) {
            infoOverlay.setPosition(undefined)
        }
    }

    useEffect(() => {
        fetchSectors()
        mapaInstancia.current = new Map({
            target: mapaRef.current,
            layers: [new TileLayer({ source: new OSM() })],
            view: new View({
                center: fromLonLat([LONGITUD_CENTRO, LONGITUD_CENTRO]),
                zoom: ZOOM,
            }),
        })

        obtenerUbicacion()

        return () => {
            if (mapaInstancia.current) {
                mapaInstancia.current.setTarget(null)
            }
        }
    }, [])

    useEffect(() => {
        if (sectors.length) {
            dibujarSectoresEnMapa()
        }
    }, [sectors, posicionActual])

    // Cerrar sugerencias al hacer clic fuera
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setSugerencias([])
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    // Añadir esta función para manejar la creación de un sector desde el marcador
    const handleCreateSectorFromMarker = (sectorData) => {
        try {
            setCargando(true);

            // Asegurar que las coordenadas del marcador se incluyan en los datos
            const datosCompletos = {
                ...sectorData,
                coordinates: newSectorCoordinates
            };

            // Enviar datos al servidor
            apiService.post("/api/sectores", datosCompletos)
                .then(response => {
                    // Actualizar la lista de sectores
                    fetchSectors();

                    // Limpiar el marcador
                    if (customMarker && customMarker.getLayer()) {
                        mapaInstancia.current.removeLayer(customMarker.getLayer());
                    }
                    setCustomMarker(null);
                    setShowSectorForm(false);
                    setNewSectorCoordinates(null);
                })
                .catch(error => {
                    console.error("Error al guardar el sector:", error);
                })
                .finally(() => {
                    setCargando(false);
                });
        } catch (error) {
            console.error("Error al guardar el sector:", error);
            setCargando(false);
        }
    };

    // Añadir esta función para cancelar la creación del sector
    const handleCancelSectorForm = () => {
        setShowSectorForm(false);
        setNewSectorCoordinates(null);

        // Eliminar el marcador
        if (customMarker && customMarker.getLayer()) {
            mapaInstancia.current.removeLayer(customMarker.getLayer());
        }
        setCustomMarker(null);
    };

    return (
        <div className="mapa-container">
            <div className="mapa-header">
                <h1 className="mapa-titulo">Mapa de Sectores</h1>

                <div className="mapa-controls">
                    <div className="search-container" ref={searchRef}>
                        <input
                            type="text"
                            value={busqueda}
                            onChange={handleBusqueda}
                            placeholder="Buscar sector..."
                            className="mapa-input"
                        />
                        {sugerencias.length > 0 && (
                            <ul className="sugerencias-lista">
                                {sugerencias.map((sector) => (
                                    <li
                                        key={sector._id}
                                        onClick={() => seleccionarSector(sector)}
                                        className="sugerencia-item"
                                    >
                                        {sector.name}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <button className="button" onClick={centrarMapa}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="8" x2="12" y2="16"></line>
                            <line x1="8" y1="12" x2="16" y2="12"></line>
                        </svg>
                        Mi ubicación
                    </button>

                    <button className="button button-secondary" onClick={resetearVista}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                        </svg>
                        Reiniciar vista
                    </button>
                    <button className="button" onClick={handleAddMarker}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                            <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        Añadir Sector
                    </button>
                </div>
            </div>


            <div className="mapa-content">
                <div ref={mapaRef} style={{ width: "100%", height: "100%" }}></div>

                {cargando && (
                    <div className="loading-indicator">
                        <div className="spinner"></div>
                    </div>
                )}

                <div className="legend">
                    <div className="legend-title">Leyenda</div>
                    <div className="legend-item">
                        <div className="legend-color" style={{ backgroundColor: COLORS.pending }}></div>
                        <span>Pendiente</span>
                    </div>
                    <div className="legend-item">
                        <div className="legend-color" style={{ backgroundColor: COLORS.inProcess }}></div>
                        <span>En proceso</span>
                    </div>
                    <div className="legend-item">
                        <div className="legend-color" style={{ backgroundColor: COLORS.success }}></div>
                        <span>Completado</span>
                    </div>
                    <div className="legend-item">
                        <div className="legend-color" style={{ backgroundColor: COLORS.userLocation }}></div>
                        <span>Mi ubicación</span>
                    </div>
                    {modoEdicion && (
                        <div className="legend-item">
                            <div className="legend-color" style={{ backgroundColor: COLORS.newMarker }}></div>
                            <span>Nuevo sector</span>
                        </div>
                    )}
                    <div className="legend-item">
                        <div className="legend-color" style={{ backgroundColor: '#e67e22' }}></div>
                        <span>Marcador personalizado</span>
                    </div>
                </div>

                {modoEdicion && (
                    <div className="modo-edicion-panel">
                        <div className="modo-edicion-header">
                            <h3>Modo Edición</h3>
                        </div>
                        <div className="modo-edicion-body">
                            <p>Haga clic en el mapa para añadir puntos. {coordenadasTemporal.length > 0 ? `Puntos: ${coordenadasTemporal.length}` : ''}
                            </p>
                            <div className="modo-edicion-actions">
                                {coordenadasTemporal.length > 0 && (
                                    <button
                                        className="button button-secondary"
                                        onClick={limpiarMarcadoresTemporales}
                                    >
                                        Limpiar puntos
                                    </button>
                                )}
                                <button
                                    className="button button-danger"
                                    onClick={() => {
                                        setModoEdicion(false);
                                        limpiarMarcadoresTemporales();
                                    }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {sectorSeleccionado && !modoEdicion && (
                    <div className="sector-info-panel">
                        <div className="sector-info-header">
                            <h2 className="sector-info-title">{sectorSeleccionado.name}</h2>
                            <button
                                className="sector-info-close"
                                onClick={() => setSectorSeleccionado(null)}
                                aria-label="Cerrar"
                            >
                                ✕
                            </button>
                        </div>
                        <div className="sector-info-body">
                            <div className="sector-info-row">
                                <span className="sector-info-label">Estado:</span>
                                <span className="sector-info-value">
                                    <span className={`sector-status status-${sectorSeleccionado.status.toLowerCase()}`}>
                                        {sectorSeleccionado.status}
                                    </span>
                                </span>
                            </div>
                            {sectorSeleccionado.description && (
                                <div className="sector-info-row">
                                    <span className="sector-info-label">Descripción:</span>
                                    <span className="sector-info-value">{sectorSeleccionado.description}</span>
                                </div>
                            )}
                            <div className="sector-info-row">
                                <span className="sector-info-label">Coordenadas:</span>
                                <span className="sector-info-value">
                                    {sectorSeleccionado.coordinates && sectorSeleccionado.coordinates.length > 0 ?
                                        `${sectorSeleccionado.coordinates[0][1].toFixed(4)}, ${sectorSeleccionado.coordinates[0][0].toFixed(4)}` :
                                        'No disponibles'}
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {showMarkerForm && (
                <div>
                    <span className="sector-info-label">Información del Marcador</span>
                    <span className="sector-info-label">Información del Marcador</span>
                </div>


            )}
            {showSectorForm && (
                <SectorForm
                    onGuardar={handleCreateSectorFromMarker}
                    onCancelar={handleCancelSectorForm}
                />
            )}
            {showSectorForm && (
                <SectorForm
                    onSubmit={handleCreateSectorFromMarker}
                    sector={{ coordinates: newSectorCoordinates }}
                    brigades={brigades}
                    circunscripciones={circunscripciones}
                    isOpen={showSectorForm}
                    onClose={handleCancelSectorForm}
                />
            )}
        </div>
    )
}



export default Mapa

