import React, { useEffect, useState } from 'react';
import SectorForm from './SectorForm';
import '../../css/sectorList.css';
import apiservice from "../../shared/services/ApiService";

const SectorList = () => {
    const [sectors, setSectors] = useState([]);
    const [filteredSectors, setFilteredSectors] = useState([]);
    const [selectedSector, setSelectedSector] = useState(null);
    const [brigades, setBrigades] = useState([]);
    const [circunscripciones, setCircunscripciones] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedCircunscripcion, setSelectedCircunscripcion] = useState('');
    const [searchText, setSearchText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [displayView, setDisplayView] = useState('grid'); // 'grid' or 'list'
    
    // Paginación
    const [currentPage, setCurrentPage] = useState(1);
    const [sectorsPerPage] = useState(3);
   

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([
                fetchSectors(),
                fetchBrigades(),
                fetchCircunscripciones()
            ]);
            setIsLoading(false);
        };
        
        fetchData();
    }, []);

    useEffect(() => {
        filterSectors();
    }, [sectors, selectedCircunscripcion, searchText, startDate, endDate]);

    const fetchSectors = async () => {
        try {
            const response = await apiservice.get(`/api/sectores`);
            const sortedSectors = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setSectors(sortedSectors);
        } catch (error) {
            console.error("Error fetching sectors:", error);
        }
    };

    const fetchBrigades = async () => {
        try {
            const response = await apiservice.get(`/api/brigadas`);
            setBrigades(response.data);
        } catch (error) {
            console.error("Error fetching brigades:", error);
        }
    };

    const fetchCircunscripciones = async () => {
        try {
            const response = await apiservice.get(`/api/circunscripciones`);
            setCircunscripciones(response.data);
        } catch (error) {
            console.error("Error fetching circunscripciones:", error);
        }
    };

    const filterSectors = () => {
        const filtered = sectors.filter(sector => {
            const matchesCircunscripcion = selectedCircunscripcion ? sector.circunscripcion === selectedCircunscripcion : true;
            const matchesSearchText = sector.name.toLowerCase().includes(searchText.toLowerCase());

            // Filtrado por fecha si las fechas están definidas
            const sectorDate = new Date(sector.fechaCreacion);
            const isWithinDateRange =
                (!startDate || sectorDate >= new Date(startDate)) &&
                (!endDate || sectorDate <= new Date(endDate));

            return matchesCircunscripcion && matchesSearchText && isWithinDateRange;
        });
        setFilteredSectors(filtered);
        
        // Resetear la página actual cuando cambian los filtros
        setCurrentPage(1);
    };

    // Lógica de paginación
    const indexOfLastSector = currentPage * sectorsPerPage;
    const indexOfFirstSector = indexOfLastSector - sectorsPerPage;
    const currentSectors = filteredSectors.slice(indexOfFirstSector, indexOfLastSector);
    const totalPages = Math.ceil(filteredSectors.length / sectorsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleDelete = async (id) => {
        setConfirmDelete(null);
        try {
            await apiservice.delete(`/api/sectores/${id}`);
            fetchSectors();
        } catch (error) {
            console.error("Error deleting sector:", error);
        }
    };

    const handleEdit = (sector) => {
        setSelectedSector(sector);
        setModalOpen(true);
    };

    const handleAdd = () => {
        setSelectedSector(null);
        setModalOpen(true);
    };

    const handleSubmit = async (sector) => {
        try {
            if (selectedSector) {
                await apiservice.put(`/api/sectores/${selectedSector._id}`, sector);
            } else {
                await apiservice.post(`/api/sectores`, sector);
            }
            setModalOpen(false);
            setSelectedSector(null);
            fetchSectors();
        } catch (error) {
            console.error("Error saving sector:", error);
            alert("Error al guardar el sector");
        }
    };

    const getCircunscripcionName = (id) => {
        const circunscripcion = circunscripciones.find(c => c._id === id);
        return circunscripcion ? circunscripcion.name : 'No asignada';
    };

    const getBrigadeName = (id) => {
        const brigade = brigades.find(b => b._id === id);
        return brigade ? brigade.name : 'No asignada';
    };

    const getStatusClass = (status) => {
        switch(status) {
            case 'Pendiente': return 'status-pending';
            case 'Proceso': return 'status-in-progress';
            case 'Listo': return 'status-completed';
            default: return '';
        }
    };

    const clearFilters = () => {
        setSelectedCircunscripcion('');
        setSearchText('');
        setStartDate('');
        setEndDate('');
    };

    return (
        <div className="sectors-container">
            <div className="sectors-header">
                <h2>Gestión de Sectores</h2>
                <button className="btn-primary" onClick={handleAdd}>
                    + Nuevo Sector
                </button>
            </div>

            <div className="filter-section">
                <div className="search-box">
                    <input
                        type="text"
                        placeholder="Buscar Sector..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <span className="search-icon">🔍</span>
                </div>

                <div className="filters-row">
                    <div className="filter-group">
                        <label>Circunscripción:</label>
                        <select 
                            value={selectedCircunscripcion} 
                            onChange={(e) => setSelectedCircunscripcion(e.target.value)}
                        >
                            <option value="">Todas</option>
                            {circunscripciones.map((circunscripcion) => (
                                <option key={circunscripcion._id} value={circunscripcion._id}>
                                    {circunscripcion.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="filter-group">
                        <label>Desde:</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>

                    <div className="filter-group">
                        <label>Hasta:</label>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>

                    <button className="btn-clear-filters" onClick={clearFilters}>
                        Limpiar Filtros
                    </button>
                </div>
            </div>

            <div className="view-controls">
                <div className="view-toggles">
                    <button 
                        className={`view-toggle ${displayView === 'grid' ? 'active' : ''}`}
                        onClick={() => setDisplayView('grid')}
                    >
                        ⊞ Cuadrícula
                    </button>
                    <button 
                        className={`view-toggle ${displayView === 'list' ? 'active' : ''}`}
                        onClick={() => setDisplayView('list')}
                    >
                        ≡ Lista
                    </button>
                </div>
                <div className="results-info">
                    Mostrando {currentSectors.length} de {filteredSectors.length} sectores
                </div>
            </div>

            {isLoading ? (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Cargando sectores...</p>
                </div>
            ) : filteredSectors.length === 0 ? (
                <div className="no-results">
                    <p>No se encontraron sectores con los filtros seleccionados</p>
                </div>
            ) : displayView === 'grid' ? (
                <>
                    <div className="sectors-grid">
                        {currentSectors.map((sector) => (
                            <div className="sector-card" key={sector._id}>
                                <div className="sector-card-header">
                                    <h3>{sector.name}</h3>
                                    <span className={`status-badge ${getStatusClass(sector.status)}`}>
                                        {sector.status}
                                    </span>
                                </div>
                                <div className="sector-card-body">
                                    <p><strong>Circunscripción:</strong> {getCircunscripcionName(sector.circunscripcion)}</p>
                                    <p><strong>Brigada:</strong> {getBrigadeName(sector.assignedBrigade)}</p>
                                    {sector.scheduledDate && (
                                        <p><strong>Fecha:</strong> {new Date(sector.scheduledDate).toLocaleDateString()}</p>
                                    )}
                                    <p><strong>Coordenadas:</strong> {sector.coordinates ? sector.coordinates.length : 0}</p>
                                </div>
                                <div className="sector-card-footer">
                                    <button className="btn-edit" onClick={() => handleEdit(sector)}>
                                        ✎ Editar
                                    </button>
                                    <button className="btn-delete" onClick={() => setConfirmDelete(sector._id)}>
                                        🗑 Eliminar
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination-container">
                        <button 
                            className="pagination-button" 
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            &laquo; Anterior
                        </button>
                        <div className="pagination-info">
                            Página {currentPage} de {totalPages}
                        </div>
                        <button 
                            className="pagination-button"
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages || totalPages === 0}
                        >
                            Siguiente &raquo;
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <table className="sectors-table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Circunscripción</th>
                                <th>Estado</th>
                                <th>Brigada</th>
                                <th>Fecha</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentSectors.map((sector) => (
                                <tr key={sector._id}>
                                    <td>{sector.name}</td>
                                    <td>{getCircunscripcionName(sector.circunscripcion)}</td>
                                    <td>
                                        <span className={`status-badge ${getStatusClass(sector.status)}`}>
                                            {sector.status}
                                        </span>
                                    </td>
                                    <td>{getBrigadeName(sector.assignedBrigade)}</td>
                                    <td>
                                        {sector.scheduledDate && new Date(sector.scheduledDate).toLocaleDateString()}
                                    </td>
                                    <td className="action-buttons">
                                        <button className="btn-edit" onClick={() => handleEdit(sector)}>
                                            ✎
                                        </button>
                                        <button className="btn-delete" onClick={() => setConfirmDelete(sector._id)}>
                                            🗑
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-container">
                        <button 
                            className="pagination-button" 
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            &laquo; Anterior
                        </button>
                        <div className="pagination-info">
                            Página {currentPage} de {totalPages}
                        </div>
                        <button 
                            className="pagination-button"
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages || totalPages === 0}
                        >
                            Siguiente &raquo;
                        </button>
                    </div>
                </>
            )}

            {isModalOpen && (
                <SectorForm
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                    onSubmit={handleSubmit}
                    sector={selectedSector}
                    brigades={brigades}
                    circunscripciones={circunscripciones}
                />
            )}

            {confirmDelete && (
                <div className="modal-overlay">
                    <div className="confirm-dialog">
                        <h3>Confirmar Eliminación</h3>
                        <p>¿Estás seguro de que quieres eliminar este sector?</p>
                        <div className="confirm-actions">
                            <button className="btn-cancel" onClick={() => setConfirmDelete(null)}>
                                Cancelar
                            </button>
                            <button className="btn-confirm" onClick={() => handleDelete(confirmDelete)}>
                                Eliminar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SectorList;