import React, { useState, useEffect } from 'react';
import apiService from '../../shared/services/ApiService';
import '../../css/HistoryDashboard.css';

const HistoryDashboard = ({ sectorId, updateHistory }) => {
    const [history, setHistory] = useState([]);
   

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const response = await apiService.get(`/api/sectores/${sectorId}/history`);
                const sortedHistory = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setHistory(sortedHistory);
            } catch (error) {
                console.error('Error al obtener el historial', error);
            }
        };
        
        if (sectorId) {
            fetchHistory();
        }
    }, [sectorId, updateHistory]); // Ahora se actualizará cada vez que updateHistory cambie
        
    return (
        <div className="history-dashboard-container">
            <h2 className="history-dashboard-title">Historial de Cambios del Sector</h2>
            <table className="history-table">
                <thead>
                    <tr>
                        <th>Estado</th>
                        <th>Brigada Asignada</th>
                        <th>Comentarios</th>
                        <th>Fecha</th>
                    </tr>
                </thead>
                <tbody className="history-table-body">
                    {history.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.status}</td>
                            <td>{entry.brigadeAssigned?.name}</td>
                            <td>{entry.comment}</td>
                            <td>{new Date(entry.date).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default HistoryDashboard;