import React, { useEffect, useState } from 'react';
import UserForm from './UserForm';
import '../../css/userList.css';
import apiservice from "../../shared/services/ApiService";

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [showForm, setShowForm] = useState(false);
    
    useEffect(() => {
        fetchUsers();
    }, []);
    
    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const response = await apiservice.get(`/api/usuarios`);
            setUsers(response.data);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const handleDelete = async (id, username) => {
        if (window.confirm(`¿Estás seguro que deseas eliminar a ${username}?`)) {
            try {
                await apiservice.delete(`/api/usuarios/${id}`);
                fetchUsers();
            } catch (error) {
                console.error("Error deleting user:", error);
            }
        }
    };
    
    const handleEdit = (user) => {
        setSelectedUser(user);
        setShowForm(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    
    const handleSubmit = async (user) => {
        setIsLoading(true);
        try {
            if (selectedUser) {
                await apiservice.put(`/api/usuarios/${selectedUser._id}`, user);
            } else {
                await apiservice.post(`/api/usuarios`, user);
            }
            setSelectedUser(null);
            setShowForm(false);
            fetchUsers();
        } catch (error) {
            console.error("Error saving user:", error);
            setIsLoading(false);
        }
    };

    const filteredUsers = users.filter(user => 
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) || 
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    return (
        <div className="user-list-container">
            <h2 className="user-title">Gestión de Usuarios</h2>
            
            <div className="user-controls">
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Buscar usuario..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>
                {!showForm && (
                    <button 
                        className="add-button" 
                        onClick={() => {
                            setSelectedUser(null);
                            setShowForm(true);
                        }}
                    >
                        <span className="button-icon">+</span> Nuevo Usuario
                    </button>
                )}
            </div>
            
            {showForm && (
                <div className="form-container">
                    <UserForm 
                        onSubmit={handleSubmit} 
                        user={selectedUser} 
                        onCancel={() => {
                            setSelectedUser(null);
                            setShowForm(false);
                        }}
                    />
                </div>
            )}
            
            {isLoading ? (
                <div className="loading-spinner">
                    <div className="spinner"></div>
                    <p>Cargando usuarios...</p>
                </div>
            ) : (
                <>
                    {filteredUsers.length > 0 ? (
                        <div className="user-list-wrapper">
                            <ul className="user-list">
                                {filteredUsers.map((user) => (
                                    <li key={user._id} className="user-item">
                                        <div className="user-info">
                                            <h3 className="user-name">{user.username}</h3>
                                            <p className="user-email">{user.email}</p>
                                        </div>
                                        <div className="user-action-buttons">
                                            <button 
                                                onClick={() => handleEdit(user)} 
                                                className="edit-button"
                                                aria-label={`Editar ${user.username}`}
                                            >
                                                Editar
                                            </button>
                                            <button 
                                                onClick={() => handleDelete(user._id, user.username)} 
                                                className="delete-button"
                                                aria-label={`Eliminar ${user.username}`}
                                            >
                                                Eliminar
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ) : (
                        <div className="no-results">
                            <p>No se encontraron usuarios{searchTerm ? ` que coincidan con "${searchTerm}"` : ''}.</p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default UserList;