import React, { useState, useEffect } from 'react';
import '../../css/brigade-form.css';

const BrigadeForm = ({ onSubmit, brigade }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (brigade) {
            setName(brigade.name || '');
            setDescription(brigade.description || '');
        } else {
            setName('');
            setDescription('');
        }
    }, [brigade]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, description });
        if (!brigade) {
            setName('');
            setDescription('');
        }
    };

    const handleCancel = () => {
        setName('');
        setDescription('');
        // If you want to clear the selected brigade, you would need to pass a function from the parent
    };

    return (
        <form onSubmit={handleSubmit} className="brigade-form">
            <div className="form-group">
                <label htmlFor="brigade-name" className="form-label">Nombre:</label>
                <input
                    id="brigade-name"
                    type="text"
                    placeholder="Nombre de la Brigada"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="brigade-input"
                />
            </div>
            
            <div className="form-group">
                <label htmlFor="brigade-description" className="form-label">Descripción:</label>
                <textarea
                    id="brigade-description"
                    placeholder="Descripción de la brigada (opcional)"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="brigade-textarea"
                    rows="3"
                />
            </div>
            
            <div className="form-actions">
                <button type="submit" className="brigade-button save-button">
                    <i className="fas fa-save"></i> Guardar
                </button>
                {brigade && (
                    <button type="button" onClick={handleCancel} className="brigade-button cancel-button">
                        <i className="fas fa-times"></i> Cancelar
                    </button>
                )}
            </div>
        </form>
    );
};

export default BrigadeForm;