import React, { useEffect, useState } from 'react';
import CircunscripcionForm from './CircunscripcionForm';
import '../../css/circunscripcionList.css';
import apiService from '../../shared/services/ApiService';

const CircunscripcionList = () => {
    const [circunscripciones, setCircunscripciones] = useState([]);
    const [filteredCircunscripciones, setFilteredCircunscripciones] = useState([]);
    const [selectedCircunscripcion, setSelectedCircunscripcion] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [displayView, setDisplayView] = useState('grid'); // 'grid' o 'list'

    // Paginación
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(3);


    useEffect(() => {
        fetchCircunscripciones();
    }, []);

    useEffect(() => {
        filterCircunscripciones();
    }, [circunscripciones, searchText]);

    const fetchCircunscripciones = async () => {
        setIsLoading(true);
        try {
            const response = await apiService.get(`/api/circunscripciones`);
            const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setCircunscripciones(sortedData);
            setFilteredCircunscripciones(sortedData);
        } catch (error) {
            console.error("Error fetching circunscripciones:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const filterCircunscripciones = () => {
        const filtered = circunscripciones.filter(item =>
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            (item.description && item.description.toLowerCase().includes(searchText.toLowerCase()))
        );
        setFilteredCircunscripciones(filtered);
        setCurrentPage(1); // Resetear a página 1 cuando se filtran los datos
    };

    // Lógica de paginación
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredCircunscripciones.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredCircunscripciones.length / itemsPerPage);

    const handleDelete = async (id) => {
        setConfirmDelete(null);
        try {
            await apiService.delete(`/api/circunscripciones/${id}`);
            fetchCircunscripciones();
        } catch (error) {
            console.error("Error deleting circunscripcion:", error);
        }
    };

    const handleEdit = (circunscripcion) => {
        setSelectedCircunscripcion(circunscripcion);
        setModalOpen(true);
    };

    const handleAdd = () => {
        setSelectedCircunscripcion(null);
        setModalOpen(true);
    };

    const handleSubmit = async (circunscripcion) => {
        try {
            if (selectedCircunscripcion) {
                await apiService.put(`/api/circunscripciones/${selectedCircunscripcion._id}`, circunscripcion);
            } else {
                await apiService.post(`/api/circunscripciones`, circunscripcion);
            }
            setModalOpen(false);
            setSelectedCircunscripcion(null);
            fetchCircunscripciones();
        } catch (error) {
            console.error("Error saving circunscripcion:", error);
            alert("Error al guardar la circunscripción");
        }
    };

    return (
        <div className="circunscripciones-container">
            <div className="circunscripciones-header">
                <h2>Gestión de Circunscripciones</h2>
                <button className="btn-primary" onClick={handleAdd}>
                    + Nueva Circunscripción
                </button>
            </div>

            <div className="filter-section">
                <div className="search-box">
                    <input
                        type="text"
                        placeholder="Buscar Circunscripción..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                    />
                    <span className="search-icon">🔍</span>
                </div>
            </div>

            <div className="view-controls">
                <div className="view-toggles">
                    <button
                        className={`view-toggle ${displayView === 'grid' ? 'active' : ''}`}
                        onClick={() => setDisplayView('grid')}
                    >
                        ⊞ Cuadrícula
                    </button>
                    <button
                        className={`view-toggle ${displayView === 'list' ? 'active' : ''}`}
                        onClick={() => setDisplayView('list')}
                    >
                        ≡ Lista
                    </button>
                </div>
                <div className="results-info">
                    Mostrando {currentItems.length} de {filteredCircunscripciones.length} circunscripciones
                </div>
            </div>

            {isLoading ? (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Cargando circunscripciones...</p>
                </div>
            ) : filteredCircunscripciones.length === 0 ? (
                <div className="no-results">
                    <p>No se encontraron circunscripciones con la búsqueda actual</p>
                </div>
            ) : displayView === 'grid' ? (
                <>
                    <div className="circunscripciones-grid">
                        {currentItems.map((circunscripcion) => (
                            <div className="circunscripcion-card" key={circunscripcion._id}>
                                <div className="circunscripcion-card-header">
                                    <h3>{circunscripcion.name}</h3>
                                </div>
                                <div className="circunscripcion-card-body">
                                    <p>{circunscripcion.description || "Sin descripción"}</p>
                                </div>
                                <div className="circunscripcion-card-footer">
                                    <button className="btn-edit" onClick={() => handleEdit(circunscripcion)}>
                                        ✎ Editar
                                    </button>
                                    <button className="btn-delete" onClick={() => setConfirmDelete(circunscripcion._id)}>
                                        🗑 Eliminar
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination-container">
                        <button
                            className="pagination-button"
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            &laquo; Anterior
                        </button>
                        <div className="pagination-info">
                            Página {currentPage} de {totalPages || 1}
                        </div>
                        <button
                            className="pagination-button"
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages || totalPages === 0}
                        >
                            Siguiente &raquo;
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <table className="circunscripciones-table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Descripción</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((circunscripcion) => (
                                <tr key={circunscripcion._id}>
                                    <td>{circunscripcion.name}</td>
                                    <td>{circunscripcion.description || "Sin descripción"}</td>
                                    <td className="action-buttons">
                                        <button className="btn-edit" onClick={() => handleEdit(circunscripcion)}>
                                            ✎
                                        </button>
                                        <button className="btn-delete" onClick={() => setConfirmDelete(circunscripcion._id)}>
                                            🗑
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-container">
                        <button
                            className="pagination-button"
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            &laquo; Anterior
                        </button>
                        <div className="pagination-info">
                            Página {currentPage} de {totalPages || 1}
                        </div>
                        <button
                            className="pagination-button"
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages || totalPages === 0}
                        >
                            Siguiente &raquo;
                        </button>
                    </div>
                </>
            )}

            {isModalOpen && (
                <CircunscripcionForm
                    onSubmit={handleSubmit}
                    circunscripcion={selectedCircunscripcion}
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                />
            )}

            {confirmDelete && (
                <div className="modal-overlay">
                    <div className="confirm-dialog">
                        <h3>Confirmar Eliminación</h3>
                        <p>¿Estás seguro de que quieres eliminar esta circunscripción?</p>
                        <div className="confirm-actions">
                            <button className="btn-cancel" onClick={() => setConfirmDelete(null)}>
                                Cancelar
                            </button>
                            <button className="btn-confirm" onClick={() => handleDelete(confirmDelete)}>
                                Eliminar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CircunscripcionList;